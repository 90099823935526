import { AxiosError } from "axios";
import toast from "react-hot-toast";

export const showErrorToast = (error: AxiosError) => {
  if (error?.response?.data?.message) {
    toast.error(error.response.data.message);
  }
  if (error?.response?.data?.detail) {
    if (Array.isArray(error?.response?.data?.detail)) {
      error.response.data.detail.map((err: { msg: string }) =>
        toast.error(err.msg)
      );
    } else {
      toast.error(error?.response?.data?.detail);
    }
  }
  if (!error?.response?.data?.message && !error?.response?.data?.detail) {
    toast.error(error?.message);
  }
};
