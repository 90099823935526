import { CityType, PromotionCityType } from "@common/Types";

export const buildCitiesOption = (cities: CityType[]) => {
  return cities?.map((city) => ({
    label: city.name,
    value: String(city.id),
  }));
};

export const buildCitiesString = (cities: Array<PromotionCityType>) => {
  return cities.map(({ city }) => city.name).join(", ");
};
