import { useEffect } from "react";

/**
 * Custom hook to load an external script.
 *
 * This hook appends a `<script>` element to the document body with the provided
 * resource URL. It also optionally accepts a callback function that will be called
 * once the script has successfully loaded.
 *
 * @param {string} resource - The URL of the external script to load.
 * @param {() => void} [callback] - Optional callback function to be executed after the script has loaded.
 *
 * @example
 * useExternalScript('https://example.com/script.js', () => {
 *   console.log('Script loaded successfully.');
 * });
 */
export const useExternalScript = (resource: string, callback?: () => void) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resource;
    script.async = true;
    document.body.appendChild(script);

    if (callback) {
      script.onload = callback;
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [resource, callback]);
};
