/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { LoadingIcon } from "@assets/Icons";
import { ROUTES } from "@common/Constants";
import { useGetRoutes, useNavigateTo } from "@common/Hooks";
import { RoleType } from "@common/Types";
import {
  cleanRoute,
  getAccessTokenLocalStorage,
  getUserRole,
} from "@common/Utils";

type Props = {
  notFoundPath: string;
  element: any;
};

export const ProtectedRoute = ({ element: Component, notFoundPath }: Props) => {
  const location = useLocation();
  const { navigateTo } = useNavigateTo();

  const userRole = getUserRole();
  const isAuthorized = getAccessTokenLocalStorage();

  const { routes, isLoading } = useGetRoutes(userRole as RoleType);

  const curPath = location.pathname;

  const hasPermission = routes?.find((path) => {
    return cleanRoute(path) === cleanRoute(curPath);
  });

  useEffect(() => {
    if (!hasPermission && isAuthorized) {
      navigateTo(notFoundPath);
    }
  }, [hasPermission]);

  if (isLoading) {
    return <LoadingIcon />; // TODO: add loader component
  }

  if (hasPermission && isAuthorized) {
    return Component; // TODO: do this when all routes will be moved <Component />
  }

  return <Navigate to={ROUTES.MAIN} replace />;
};
