import { appConfig } from "@api/config";
import { ReasonAniFraudsQueryParams } from "@common/Types";

/**
 * Сервисы для anti fraud
 */

const src = appConfig.api.antifraud;

export const antiFraudPath = {
  anti_fraud: `${src}/blocked-payments/`,

  anti_fraud_settings: `${src}/settings/`,

  anti_fraud_reason: ({ paymentId }: ReasonAniFraudsQueryParams) =>
    `${src}/${paymentId}/admin-descriptions/`,
};
