import clsx, { ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const customTwMerge = extendTailwindMerge({
  classGroups: {
    "font-size": [
      "text-heading-1-a",
      "text-heading-1-b",
      "text-heading-2-a",
      "text-heading-2-b",
      "text-heading-3-a",
      "text-heading-3-b",
      "text-caption-a",
      "text-caption-b",
      "text-body-1-a",
      "text-body-1-b",
      "text-body-2-a",
      "text-body-2-b",
    ],
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
