import React from "react";

type Props = {
  rotation: "top" | "right" | "bottom" | "left" | "leftTop";
};

const rotateIconClassnames = {
  top: "rotate-0",
  right: "rotate-90",
  bottom: "rotate-180",
  left: "-rotate-90",
  leftTop: "rotate-45",
};

export const ArrowIcon = ({ rotation = "top" }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={rotateIconClassnames[rotation]}
    >
      <path
        d="M12 5V19"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99902 10L12 4.99902L17.001 10"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
