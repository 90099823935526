import React from "react";

export const NetworkErrorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.59424 7.804C7.34124 2.732 16.6592 2.732 22.4062 7.804"
        stroke="#D33333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.9999V9.18994"
        stroke="#D33333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.999 19.5C11.861 19.5 11.749 19.612 11.75 19.75C11.75 19.888 11.862 20 12 20C12.138 20 12.25 19.888 12.25 19.75C12.25 19.612 12.138 19.5 11.999 19.5"
        stroke="#D33333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.409 11.9999C18.136 10.8319 16.612 10.0499 15 9.60791"
        stroke="#D33333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00031 9.60791C7.38831 10.0499 5.86431 10.8319 4.59131 11.9999"
        stroke="#D33333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 14.7769C15.508 15.0519 15.99 15.3929 16.42 15.8209"
        stroke="#D33333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0001 14.7769C8.4921 15.0519 8.0101 15.3929 7.5791 15.8209"
        stroke="#D33333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
