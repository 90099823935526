import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Custom hook to parse query parameters from the URL.
 *
 * This hook utilizes `useLocation` from `react-router-dom` to access the current
 * URL's search parameters, and returns a `URLSearchParams` object. The `URLSearchParams`
 * object provides convenient methods for working with the query string.
 *
 * @returns {URLSearchParams} An instance of `URLSearchParams` containing the query parameters.
 */
export const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
