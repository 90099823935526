import React from "react";

import { AppProvidersLayout } from "@components/Layouts";
import { AppRouter } from "@features/index";

export const AppRoot = () => {
  return (
    <AppProvidersLayout>
      <AppRouter />
    </AppProvidersLayout>
  );
};
