import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useNavigateTo } from "./use-navigate-to";

/**
 * A custom hook that manages the state of the active tab, allowing you to navigate between tabs
 * and persist the active tab in the URL query parameters.
 *
 * @param {number} defaultTab - The default tab index to use if no tab is specified in the URL.
 * @returns {Object} An object containing:
 *   - `activeTab` {number}: The current active tab index.
 *   - `setActiveTab` {Function}: A function to set the active tab index and update the URL.
 */

export const useTabControl = (defaultTab: number) => {
  const location = useLocation();
  const { navigateTo } = useNavigateTo();
  const searchParams = queryString.parse(location.search);
  const activeTab = Number(searchParams.tab) || defaultTab;

  const setActiveTab = (tabIndex: number) => {
    navigateTo(
      `${location.pathname}?${queryString.stringify({
        tab: tabIndex,
      })}`,
      {
        replace: true,
      },
    );
  };

  return {
    activeTab,
    setActiveTab,
  };
};
