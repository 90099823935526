import React from "react";

import { ROLES_NAVI } from "@common/Constants";
import {
  AvailableFilterKeys,
  LocalFiltersType,
  PartialFiltersType,
} from "@common/Types";
import { AuthRender, CitiesFilter, InlineSelect } from "@components/Shared";

type FilterComponentPropsType = {
  filters: PartialFiltersType;
  setFilters: (filters: PartialFiltersType) => void;
  filterData: LocalFiltersType[];
  field: AvailableFilterKeys;
};

type AdditionalFiltersType = "city_id" | "inline";

type FilterObjectType = {
  [key in AdditionalFiltersType]: {
    component: (props: FilterComponentPropsType) => React.JSX.Element;
  };
};

export const filtersSchema: FilterObjectType = {
  city_id: {
    component: (props: FilterComponentPropsType) => (
      <AuthRender roles={[ROLES_NAVI.SUPERADMIN]}>
        <CitiesFilter {...props} />
      </AuthRender>
    ),
  },
  inline: {
    component: (props: FilterComponentPropsType) => (
      <InlineSelect<PartialFiltersType> data={props.filterData} {...props} />
    ),
  },
};
