import React from "react";

import { NetworkErrorIcon } from "@assets/Icons";
import { Col, Row, Typography } from "@components/Elements";

export const NetworkErrorCard = () => {
  return (
    <Row className="p-[12px] gap-[16px] border-b-[1px] border-neutral-border-100">
      <NetworkErrorIcon />
      <Col
        className="w-full flex-nowrap gap-[4px]"
        justifyContent="justify-between"
      >
        <Typography variant="body-2-b" className="font-bold">
          Отсутствует соединение
        </Typography>
        <Typography variant="caption-a" className="text-gray-400">
          убедитесь, что ваш компьютер имеет активное подключение к Интернету
        </Typography>
      </Col>
    </Row>
  );
};
