/**
 * Сервисы для tariffs
 */

import { appConfig } from "@api/config";

const src = appConfig.api.landing;

export const landingTariffsPath = {
  tariffs: `${src}/tariffs/`,
};
