import clsx from "clsx";
import React from "react";

import { FontVariant } from "@common/Types";

import { colorClasses, ColorClassKey } from "./variants";

export interface TypographyProps {
  variant?: FontVariant;
  color?: ColorClassKey;
  children: React.ReactNode;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}

/**
 * A Typography component for displaying text with different font variants and colors.
 *
 * @param {TypographyProps} props - The props for the Typography component.
 * @returns {React.ReactElement} The Typography component.
 */
export const Typography: React.FC<TypographyProps> = ({
  variant = "body-1-a",
  color = "neutral-0",
  children,
  className,
  as: Component = "span",
}) => {
  const variantClass = `text-${variant}`;
  const classList = clsx(variantClass, colorClasses[color], className);

  return <Component className={classList}>{children}</Component>;
};
