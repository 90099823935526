import { apiClient } from "@common/Libs/axios";
import { LoginResponse, MeResponse } from "@common/Types";
import { convertPhoneNumber } from "@common/Utils";

import { authPaths } from "./paths";

/**
 * Описание методов авторизации.
 */

export const authService = {
  login: (data: FormData) =>
    apiClient.post<LoginResponse>(authPaths.login, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }),
  getRole: (token: string) =>
    apiClient.get<MeResponse>(authPaths.role, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  sendCode: (phone: string) =>
    apiClient.post(authPaths.send_code, {
      phone: convertPhoneNumber(phone),
    }),
};
