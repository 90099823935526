import React from "react";

export const MasterCardLogo = ({ className }: { className: string }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="300px"
      height="180px"
      viewBox="0 0 300 180"
      enableBackground="new 0 0 300 180"
      xmlSpace="preserve"
      className={className}
    >
      <g id="layer1">
        <g id="g10305">
          <path
            id="path2268"
            fill="#FF9900"
            d="M298.032,90.5c0.014,48.936-39.646,88.614-88.582,88.627
			c-48.937,0.012-88.614-39.646-88.627-88.582c0-0.016,0-0.029,0-0.045c-0.013-48.935,39.647-88.615,88.581-88.628
			c48.937-0.013,88.615,39.647,88.628,88.583C298.032,90.47,298.032,90.484,298.032,90.5z"
          />
          <path
            id="path1350"
            fill="#CC0000"
            d="M90.001,1.895C41.355,2.204,1.967,41.781,1.967,90.5
			c0,48.909,39.695,88.604,88.605,88.604c22.955,0,43.879-8.748,59.624-23.086c-0.001,0-0.003-0.002-0.007-0.004h0.019
			c3.224-2.938,6.231-6.108,8.995-9.488H141.05c-2.424-2.928-4.627-5.979-6.606-9.127h31.308c1.904-3.047,3.628-6.211,5.158-9.488
			h-41.635c-1.419-3.042-2.651-6.153-3.703-9.309h49.045c2.956-8.832,4.56-18.281,4.56-28.103c0-6.512-0.706-12.861-2.042-18.974
			h-54.164c0.671-3.146,1.518-6.254,2.528-9.308h49.063c-1.097-3.25-2.371-6.417-3.82-9.487H129.27
			c1.496-3.196,3.191-6.305,5.084-9.307h31.285c-2.082-3.317-4.386-6.486-6.877-9.488h-17.443c2.697-3.174,5.666-6.163,8.889-8.95
			c-15.746-14.34-36.676-23.09-59.636-23.09C90.381,1.895,90.192,1.894,90.001,1.895z"
          />
          <path
            id="use9412"
            fill="#FCB340"
            d="M289.143,136.82c0.482,0,0.951,0.125,1.409,0.371c0.46,0.246,0.814,0.601,1.07,1.062
			c0.256,0.456,0.384,0.937,0.384,1.435c0,0.492-0.127,0.968-0.379,1.424c-0.251,0.455-0.605,0.81-1.061,1.063
			c-0.451,0.249-0.928,0.375-1.424,0.375s-0.972-0.126-1.426-0.375c-0.455-0.254-0.807-0.607-1.063-1.063
			c-0.252-0.456-0.377-0.932-0.377-1.424c0-0.498,0.127-0.979,0.384-1.435c0.258-0.461,0.614-0.813,1.071-1.062
			C288.193,136.945,288.662,136.82,289.143,136.82 M289.143,137.295c-0.401,0-0.793,0.104-1.176,0.311
			c-0.38,0.207-0.677,0.5-0.891,0.888c-0.217,0.382-0.325,0.778-0.325,1.194c0,0.412,0.106,0.81,0.315,1.188
			c0.214,0.377,0.51,0.673,0.888,0.885c0.381,0.211,0.776,0.315,1.188,0.315c0.414,0,0.81-0.104,1.189-0.315
			c0.378-0.212,0.673-0.508,0.884-0.885c0.209-0.378,0.313-0.775,0.313-1.188c0-0.416-0.106-0.813-0.321-1.194
			c-0.213-0.388-0.511-0.681-0.894-0.888C289.934,137.398,289.544,137.295,289.143,137.295 M287.887,141.27v-3.082h1.062
			c0.36,0,0.622,0.028,0.784,0.088c0.162,0.057,0.291,0.154,0.388,0.297c0.095,0.141,0.144,0.291,0.144,0.451
			c0,0.226-0.08,0.422-0.242,0.588c-0.158,0.166-0.373,0.261-0.639,0.281c0.109,0.045,0.196,0.102,0.264,0.164
			c0.125,0.12,0.275,0.323,0.455,0.61l0.375,0.603h-0.606l-0.272-0.485c-0.215-0.382-0.388-0.62-0.521-0.718
			c-0.091-0.069-0.224-0.105-0.397-0.105h-0.293v1.311h-0.5 M288.385,139.535h0.604c0.288,0,0.483-0.044,0.588-0.129
			c0.106-0.088,0.159-0.2,0.159-0.342c0-0.092-0.024-0.174-0.075-0.244c-0.052-0.073-0.122-0.125-0.213-0.162
			c-0.089-0.035-0.255-0.055-0.497-0.055h-0.564v0.932"
          />
        </g>
        <g id="g16480">
          <g id="g13802" transform="translate(-13.74405,15.9939)">
            <path
              id="path13804"
              fill="#000066"
              d="M133.719,99.926l1.18-8.02c-0.645,0-1.593,0.279-2.431,0.279
				c-3.284,0-3.694-1.755-3.436-3.037l3.236-16.13h4.992l1.029-9.103h-4.705l0.958-5.516H124.7
				c-0.208,0.208-5.568,31.022-5.568,34.776c0,5.555,3.118,8.027,7.516,7.988C130.09,101.134,132.773,100.181,133.719,99.926z"
            />
            <path
              id="path13806"
              fill="#000066"
              d="M136.706,84.638c0,13.332,8.799,16.499,16.297,16.499
				c6.921,0,10.55-1.604,10.55-1.604l1.662-9.1c0,0-5.848,2.378-10.601,2.378c-10.131,0-8.355-7.554-8.355-7.554l19.463,0.059
				c0,0,1.239-6.111,1.239-8.602c0-6.217-3.387-13.849-13.745-13.849C143.73,62.867,136.706,73.088,136.706,84.638z M153.252,71.313
				c5.324,0,4.342,5.984,4.342,6.469H147.12C147.12,77.162,148.109,71.313,153.252,71.313z"
            />
            <path
              id="path13808"
              fill="#000066"
              d="M212.99,99.923l1.689-10.284c0,0-4.632,2.321-7.807,2.321
				c-6.693,0-9.378-5.11-9.378-10.601c0-11.137,5.758-17.265,12.168-17.265c4.808,0,8.665,2.699,8.665,2.699l1.54-9.993
				c0,0-4.554-3.289-9.456-3.308c-14.745-0.058-23.182,10.208-23.182,27.955c0,11.763,6.248,19.768,17.506,19.768
				C207.918,101.215,212.99,99.923,212.99,99.923z"
            />
            <path
              id="path13810"
              fill="#000066"
              d="M81.83,63.012c-6.469,0-11.427,2.079-11.427,2.079l-1.37,8.127
				c0,0,4.093-1.663,10.281-1.663c3.513,0,6.083,0.395,6.083,3.25c0,1.734-0.314,2.374-0.314,2.374s-2.772-0.231-4.056-0.231
				c-9.21,0-16.729,3.482-16.729,13.98c0,8.273,5.623,10.17,9.108,10.17c6.657,0,9.292-4.203,9.444-4.215l-0.077,3.488
				c0,0,8.306,0,8.307,0l3.706-25.98C94.786,63.366,85.17,63.012,81.83,63.012z M83.268,84.108c0.181,1.586-0.41,9.086-6.092,9.086
				c-2.93,0-3.691-2.24-3.691-3.562c0-2.584,1.403-5.683,8.315-5.683C83.41,83.949,82.997,84.065,83.268,84.108z"
            />
            <path
              id="path13812"
              fill="#000066"
              d="M103.615,100.906c2.125,0,14.272,0.541,14.272-11.994
				c0-11.721-11.244-9.404-11.244-14.114c0-2.342,1.833-3.08,5.184-3.08c1.329,0,6.447,0.423,6.447,0.423l1.189-8.33
				c0,0.001-3.312-0.741-8.704-0.741c-6.979,0-14.063,2.786-14.063,12.318c0,10.802,11.812,9.717,11.812,14.267
				c0,3.037-3.3,3.287-5.844,3.287c-4.401,0-8.363-1.511-8.377-1.438l-1.259,8.245C93.257,99.819,95.702,100.906,103.615,100.906z"
            />
            <path
              id="path13814"
              fill="#000066"
              d="M290.807,55.455l-1.705,12.709c0,0-3.553-4.905-9.112-4.905
				c-10.459,0-15.849,10.423-15.849,22.396c0,7.73,3.844,15.307,11.699,15.307c5.651,0,8.784-3.941,8.784-3.941l-0.415,3.365h9.178
				l7.207-44.862L290.807,55.455z M286.755,80.156c0,4.983-2.468,11.64-7.581,11.64c-3.396,0-4.988-2.851-4.988-7.324
				c0-7.315,3.285-12.14,7.432-12.14C285.012,72.332,286.755,74.662,286.755,80.156z"
            />
            <path
              id="path13816"
              fill="#000066"
              d="M30.749,100.423l5.743-33.87l0.844,33.87h6.499l12.125-33.87l-5.371,33.87h9.658
				l7.437-44.922l-15.342-0.117l-9.126,27.504l-0.25-27.387h-14.06l-7.544,44.922H30.749L30.749,100.423z"
            />
            <path
              id="path13818"
              fill="#000066"
              d="M176.101,100.487c2.746-15.615,3.724-27.947,11.732-25.393
				c1.15-6.044,3.891-11.3,5.143-13.858c0,0-0.396-0.589-2.871-0.589c-4.225,0-9.866,8.574-9.866,8.574l0.843-5.301h-8.786
				l-5.884,36.566H176.101z"
            />
            <g id="use14699" transform="translate(845.3001,0)">
              <path
                id="path13810_1_"
                fill="#000066"
                d="M-612.55,63.012c-6.472,0-11.43,2.079-11.43,2.079l-1.369,8.127
					c0,0,4.095-1.663,10.28-1.663c3.514,0,6.083,0.395,6.083,3.25c0,1.734-0.313,2.374-0.313,2.374s-2.771-0.231-4.055-0.231
					c-9.211,0-16.729,3.482-16.729,13.98c0,8.273,5.622,10.17,9.107,10.17c6.655,0,9.292-4.203,9.443-4.215l-0.078,3.488h8.309
					l3.705-25.98C-599.596,63.366-609.212,63.012-612.55,63.012z M-611.114,84.108c0.18,1.586-0.411,9.086-6.092,9.086
					c-2.932,0-3.692-2.24-3.692-3.562c0-2.584,1.402-5.683,8.315-5.683C-610.972,83.949-611.384,84.065-611.114,84.108z"
              />
            </g>
            <path
              id="use14701"
              fill="#000066"
              d="M255.266,100.487c1.508-11.488,4.299-27.616,11.731-25.393
				c1.149-6.044,0.041-6.028-2.433-6.028c-4.228,0-5.164,0.154-5.164,0.154l0.844-5.301h-8.785l-5.884,36.567H255.266
				L255.266,100.487z"
            />
          </g>
          <g id="g10289">
            <path
              id="path4157"
              fill="#FFFFFF"
              d="M122.434,113.059l1.181-8.019c-0.645,0-1.594,0.276-2.431,0.276
				c-3.284,0-3.646-1.746-3.437-3.037l2.653-16.362h4.991l1.205-8.87h-4.706l0.958-5.516h-9.434
				c-0.208,0.208-5.569,31.023-5.569,34.775c0,5.555,3.119,8.029,7.517,7.989C118.806,114.266,121.488,113.313,122.434,113.059z"
            />
            <path
              id="path4155"
              fill="#FFFFFF"
              d="M125.423,97.77c0,13.332,8.8,16.5,16.297,16.5c6.92,0,9.965-1.547,9.965-1.547
				l1.662-9.099c0,0-5.264,2.319-10.018,2.319c-10.13,0-8.356-7.553-8.356-7.553h19.172c0,0,1.238-6.113,1.238-8.604
				c0-6.216-3.094-13.79-13.452-13.79C132.445,75.998,125.423,86.219,125.423,97.77z M141.967,84.445
				c5.324,0,4.342,5.983,4.342,6.467h-10.474C135.835,90.294,136.825,84.445,141.967,84.445z"
            />
            <path
              id="path4151"
              fill="#FFFFFF"
              d="M201.707,113.055l1.688-10.285c0,0-4.629,2.321-7.806,2.321
				c-6.692,0-9.376-5.11-9.376-10.6c0-11.137,5.758-17.264,12.168-17.264c4.807,0,8.665,2.699,8.665,2.699l1.54-9.993
				c0,0-5.721-2.315-10.625-2.315c-10.891,0-21.486,9.448-21.486,27.192c0,11.766,5.721,19.537,16.979,19.537
				C196.637,114.348,201.707,113.055,201.707,113.055z"
            />
            <path
              id="path4149"
              fill="#FFFFFF"
              d="M70.547,76.143c-6.469,0-11.428,2.079-11.428,2.079l-1.369,8.127
				c0,0,4.093-1.663,10.28-1.663c3.513,0,6.083,0.395,6.083,3.25c0,1.734-0.315,2.374-0.315,2.374s-2.771-0.232-4.054-0.232
				c-8.159,0-16.73,3.482-16.73,13.98c0,8.272,5.623,10.17,9.108,10.17c6.656,0,9.525-4.319,9.678-4.332l-0.311,3.605h8.307
				l3.706-25.981C83.502,76.498,73.887,76.143,70.547,76.143z M72.568,97.297c0.18,1.587-0.995,9.026-6.675,9.026
				c-2.93,0-3.692-2.238-3.692-3.562c0-2.582,1.403-5.682,8.316-5.682C72.125,97.081,72.297,97.253,72.568,97.297z"
            />
            <path
              id="path4145"
              fill="#FFFFFF"
              d="M92.331,114.038c2.125,0,14.273,0.54,14.273-11.995
				c0-11.719-11.245-9.404-11.245-14.112c0-2.344,1.833-3.082,5.183-3.082c1.33,0,6.447,0.423,6.447,0.423l1.19-8.33
				c0,0.001-3.312-0.741-8.704-0.741c-6.979,0-14.063,2.786-14.063,12.318c0,10.801,11.812,9.717,11.812,14.267
				c0,3.037-3.3,3.284-5.843,3.284c-4.401,0-8.364-1.51-8.378-1.438l-1.258,8.246C81.973,112.948,84.417,114.038,92.331,114.038z"
            />
            <path
              id="path4139"
              fill="#FFFFFF"
              d="M279.852,68.668l-2.035,12.627c0,0-3.551-4.905-9.11-4.905
				c-8.644,0-15.849,10.422-15.849,22.397c0,7.73,3.843,15.304,11.699,15.304c5.651,0,8.784-3.94,8.784-3.94l-0.415,3.365h9.176
				l7.207-44.863L279.852,68.668z M275.471,93.288c0,4.983-2.467,11.639-7.582,11.639c-3.395,0-4.986-2.85-4.986-7.323
				c0-7.314,3.285-12.14,7.43-12.14C273.729,85.463,275.471,87.796,275.471,93.288z"
            />
            <path
              id="path4133"
              fill="#FFFFFF"
              d="M19.466,113.555l5.743-33.87l0.843,33.87h6.5l12.125-33.87l-5.371,33.87h9.658
				l7.438-44.923H41.467l-9.301,27.563l-0.484-27.563H17.915l-7.545,44.923H19.466z"
            />
            <path
              id="path4131"
              fill="#FFFFFF"
              d="M164.818,113.617c2.746-15.616,3.255-28.296,9.808-25.975
				c1.147-6.044,2.254-8.382,3.506-10.94c0,0-0.587-0.123-1.819-0.123c-4.225,0-7.355,5.772-7.355,5.772l0.841-5.301h-8.784
				l-5.885,36.567H164.818z"
            />
            <g id="use8523" transform="translate(847.0062,0)">
              <path
                id="path4149_1_"
                fill="#FFFFFF"
                d="M-623.531,76.143c-6.469,0-11.428,2.079-11.428,2.079l-1.368,8.127
					c0,0,4.093-1.663,10.28-1.663c3.513,0,6.081,0.395,6.081,3.25c0,1.734-0.313,2.374-0.313,2.374s-2.771-0.232-4.055-0.232
					c-8.158,0-16.729,3.482-16.729,13.98c0,8.272,5.622,10.17,9.107,10.17c6.656,0,9.525-4.319,9.677-4.332l-0.309,3.605
					c0,0,8.304,0,8.307,0l3.705-25.981C-610.575,76.498-620.191,76.143-623.531,76.143z M-621.507,97.297
					c0.18,1.587-0.996,9.026-6.678,9.026c-2.93,0-3.69-2.238-3.69-3.562c0-2.582,1.403-5.682,8.315-5.682
					C-621.952,97.081-621.781,97.253-621.507,97.297z"
              />
            </g>
            <g id="use8525" transform="translate(442.2857,0)">
              <path
                id="path4131_1_"
                fill="#FFFFFF"
                d="M-198.263,113.617c2.747-15.616,3.256-28.296,9.807-25.975
					c1.149-6.044,2.257-8.382,3.508-10.94c0,0-0.587-0.123-1.819-0.123c-4.225,0-7.355,5.772-7.355,5.772l0.841-5.301h-8.784
					l-5.885,36.567H-198.263z"
              />
            </g>
            <path
              id="text9407"
              fill="#FFFFFF"
              d="M289.105,107.975c0.479,0,0.951,0.123,1.406,0.373
				c0.459,0.242,0.816,0.598,1.072,1.059c0.257,0.458,0.383,0.935,0.383,1.434c0,0.493-0.126,0.969-0.379,1.424
				c-0.251,0.455-0.604,0.812-1.059,1.063c-0.454,0.25-0.93,0.376-1.424,0.376c-0.498,0-0.974-0.126-1.429-0.376
				c-0.454-0.253-0.806-0.608-1.058-1.063c-0.256-0.455-0.381-0.931-0.381-1.424c0-0.499,0.127-0.976,0.384-1.434
				c0.258-0.461,0.616-0.815,1.073-1.059C288.154,108.098,288.626,107.975,289.105,107.975 M289.105,108.447
				c-0.401,0-0.793,0.104-1.176,0.313c-0.382,0.204-0.679,0.499-0.894,0.885c-0.214,0.381-0.322,0.78-0.322,1.194
				s0.104,0.81,0.313,1.188c0.213,0.377,0.509,0.673,0.891,0.886c0.378,0.208,0.773,0.313,1.188,0.313
				c0.412,0,0.81-0.105,1.188-0.313c0.378-0.213,0.674-0.509,0.884-0.886c0.211-0.381,0.314-0.774,0.314-1.188
				s-0.107-0.813-0.321-1.194c-0.213-0.386-0.51-0.681-0.894-0.885C289.896,108.552,289.507,108.447,289.105,108.447
				 M287.85,112.423v-3.083h1.061c0.361,0,0.625,0.029,0.785,0.088c0.162,0.055,0.289,0.154,0.388,0.297
				c0.097,0.142,0.146,0.291,0.146,0.451c0,0.225-0.082,0.422-0.244,0.588c-0.158,0.166-0.371,0.262-0.637,0.28
				c0.106,0.046,0.194,0.101,0.262,0.163c0.123,0.122,0.275,0.326,0.455,0.611l0.377,0.604h-0.609l-0.271-0.485
				c-0.216-0.383-0.389-0.621-0.521-0.718c-0.091-0.071-0.224-0.106-0.399-0.106h-0.291v1.311L287.85,112.423 M288.348,110.688
				h0.604c0.289,0,0.484-0.043,0.588-0.129c0.105-0.084,0.16-0.199,0.16-0.342c0-0.091-0.025-0.173-0.075-0.242
				c-0.051-0.074-0.122-0.127-0.213-0.164c-0.091-0.035-0.254-0.053-0.498-0.053h-0.565V110.688"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
