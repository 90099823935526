import React, { useEffect } from "react";

import FAILURE from "./assets/images/card-fail.png";
import { CardComponent } from "./card-component";

export const CardLinkFailure: React.FC = () => {
  const paymentResultFail = () => {
    console.log("Fail");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      paymentResultFail();
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <CardComponent
      imageSrc={FAILURE}
      title="Ошибка!"
      text="Операция прошла неуспешно. Свяжитесь со своим банком."
      paymentResult={paymentResultFail}
    />
  );
};
