import React from "react";

export const DriversIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4693 3.02278C13.833 4.38649 13.833 6.5975 12.4693 7.96121C11.1056 9.32492 8.89455 9.32492 7.53084 7.96121C6.16713 6.5975 6.16713 4.38649 7.53084 3.02278C8.89455 1.65907 11.1056 1.65907 12.4693 3.02278"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.363 12.796C13.064 12.277 11.54 11.991 10 11.991C5.952 11.991 2 13.958 2 16.983V17.983C2 18.535 2.448 18.983 3 18.983H12.413"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 22C14.239 22 12 19.762 12 17C12 14.296 14.3 11.997 17.004 12C19.764 12.002 22 14.24 22 17C22 19.761 19.762 22 17 22"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 16.78V14.78"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.001 19.25C17.078 19.25 17.14 19.188 17.139 19.111C17.139 19.034 17.077 18.972 17 18.972C16.923 18.972 16.861 19.034 16.861 19.111C16.861 19.188 16.923 19.25 17.001 19.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
