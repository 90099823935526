import { NativeFetcher } from "@api/config";

import { paymentsPath } from "./paths";

/**
 * Описание методов payments.
 */

export const paymentsService = {
  saveCardRequest: (accessToken: string) => {
    return NativeFetcher({
      path: paymentsPath.save,
      token: accessToken,
      method: "POST",
    });
  },
};
