import { ROUTES } from "@common/Constants";

export const getPageName = (pathname: string) => {
  if (pathname === ROUTES.DRIVERS_LANDING) {
    return "driver";
  } else if (pathname === ROUTES.PARTNER_LANDING) {
    return "partner";
  } else {
    return "home";
  }
};
