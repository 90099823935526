import React from "react";
import { useTranslation } from "react-i18next";

export const DeleteRequestComplete = () => {
  const { t } = useTranslation();

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-col text-white gap-[1em]"}>
        <div className={"flex flex-col text-[18px] font-bold text-center"}>
          <span>{t("delete-account.complete-text")}</span>
        </div>
      </div>
    </div>
  );
};
