import { TextInput as MantineTextInput } from "@mantine/core";
import React from "react";

import { Props, TextInputView } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * A customizable text input component.
 *
 * This component wraps Mantine's `TextInput` and applies custom styles based on the `view` prop.
 * It handles different states such as `disabled` and `error`, and allows additional styling through classNames.
 *
 * @param {Props} props - The properties for the TextInput component.
 * @returns {React.FC} - The TextInput component.
 */

export const TextInput: React.FC<Props> = ({
  children,
  view = "default",
  error,
  disabled,
  ...props
}) => {
  let currentView = view as TextInputView;

  if (disabled) {
    currentView = view === "no-border" ? "no-border-disabled" : "disabled";
  } else if (error) {
    currentView = "error";
  }

  return (
    <MantineTextInput
      error={error}
      disabled={disabled}
      classNames={{
        icon: "mr-[6px]",
        ...VIEW_CLASSES[currentView],
      }}
      {...props}
    >
      {children}
    </MantineTextInput>
  );
};
