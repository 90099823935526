import clsx from "clsx";
import React from "react";

// local deps
import { Flexbox } from "./flexbox";
import { ColProps } from "./types";

/**
 * Col component for creating a vertical flex container.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the column.
 * @param {string} [props.className] - Optional additional class names.
 * @param {string} [props.justifyContent='flex-start'] - Justify content alignment.
 * @param {string} [props.alignItems='flex-start'] - Align items alignment.
 * @returns {JSX.Element} The rendered component.
 */

export const Col: React.FC<ColProps> = ({
  children,
  className,
  justifyContent,
  alignItems,
  ...props
}) => {
  return (
    <Flexbox
      className={clsx(className)}
      flexDirection="flex-col"
      justifyContent={justifyContent}
      alignItems={alignItems}
      {...props}
    >
      {children}
    </Flexbox>
  );
};
