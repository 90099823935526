import clsx from "clsx";
import React from "react";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const Heading = ({ children, className }: Props) => {
  return (
    <h4
      className={clsx(
        "max-w-4xl font-sans font-bold leading-none tracking-tight md:mx-auto text-center text-gray-200 lg:text-5xl md:text-4xl sm:text-3xl text-3xl",
        className,
      )}
    >
      {children}
    </h4>
  );
};
