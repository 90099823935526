import React from "react";

type Props = {
  className?: string;
};

export const BurgerMenu = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <rect x="3" y="4" width="18" height="2" fill="white" />
      <rect x="3" y="11" width="18" height="2" fill="white" />
      <rect x="3" y="18" width="18" height="2" fill="white" />
    </svg>
  );
};

export const CloseMenu = ({ className }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58295 4.10053C3.87584 3.80764 4.35072 3.80764 4.64361 4.10053L20.6436 20.1005C20.9365 20.3934 20.9365 20.8683 20.6436 21.1612C20.3507 21.4541 19.8758 21.4541 19.583 21.1612L3.58295 5.16119C3.29006 4.8683 3.29006 4.39342 3.58295 4.10053Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6436 4.10053C20.9365 4.39342 20.9365 4.8683 20.6436 5.16119L4.64361 21.1612C4.35072 21.4541 3.87584 21.4541 3.58295 21.1612C3.29006 20.8683 3.29006 20.3934 3.58295 20.1005L19.583 4.10053C19.8758 3.80764 20.3507 3.80764 20.6436 4.10053Z"
        fill="white"
      />
    </svg>
  );
};

export const MotorBgCover = ({ className }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="545"
      height="358"
      viewBox="0 0 545 358"
      fill="#2F3437"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M414.808 331.466C414.808 331.466 462.709 255.274 462.709 186.167C462.709 121.225 424.033 93.1376 400.828 90.6062C379.951 88.3288 355.653 89.319 336.483 107.12C310.859 130.912 305.873 182.012 336.483 185.986C368.64 190.161 369.163 152.708 369.163 152.708C394.585 131.987 438.641 201.274 373.852 242.865C329.407 271.396 289.532 246.015 272.169 233.415C254.806 246.015 214.93 271.397 170.485 242.865C105.696 201.275 149.752 131.987 175.174 152.708C175.174 152.708 175.697 190.162 207.854 185.987C238.464 182.013 233.478 130.913 207.854 107.12C188.684 89.3195 164.386 88.3294 143.509 90.6068C120.304 93.1382 81.6276 121.226 81.6276 186.167C81.6276 255.275 129.529 331.467 129.529 331.467C129.529 331.467 71.6665 390.91 32.8171 331.062C-6.0323 271.214 -18.8167 139.834 44.2283 69.6213C117.765 -12.2758 198.632 23.4229 238.464 44.4222C240.393 15.2107 250.72 0.323935 272.168 0.32373C293.617 0.32373 303.944 15.2099 305.873 44.4216C345.705 23.4223 426.572 -12.2764 500.109 69.6207C563.154 139.834 550.369 271.214 511.52 331.062C472.67 390.909 414.808 331.466 414.808 331.466Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M414.808 331.466C414.808 331.466 462.709 255.274 462.709 186.167C462.709 121.225 424.033 93.1376 400.828 90.6062C379.951 88.3288 355.653 89.319 336.483 107.12C310.859 130.912 305.873 182.012 336.483 185.986C368.64 190.161 369.163 152.708 369.163 152.708C394.585 131.987 438.641 201.274 373.852 242.865C329.407 271.396 289.532 246.015 272.169 233.415C254.806 246.015 214.93 271.397 170.485 242.865C105.696 201.275 149.752 131.987 175.174 152.708C175.174 152.708 175.697 190.162 207.854 185.987C238.464 182.013 233.478 130.913 207.854 107.12C188.684 89.3195 164.386 88.3294 143.509 90.6068C120.304 93.1382 81.6276 121.226 81.6276 186.167C81.6276 255.275 129.529 331.467 129.529 331.467C129.529 331.467 71.6665 390.91 32.8171 331.062C-6.0323 271.214 -18.8167 139.834 44.2283 69.6213C117.765 -12.2758 198.632 23.4229 238.464 44.4222C240.393 15.2107 250.72 0.323935 272.168 0.32373C293.617 0.32373 303.944 15.2099 305.873 44.4216C345.705 23.4223 426.572 -12.2764 500.109 69.6207C563.154 139.834 550.369 271.214 511.52 331.062C472.67 390.909 414.808 331.466 414.808 331.466Z"
      />
    </svg>
  );
};
