import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { supportChatService } from "@api/services";
import { ChatListTypes, ChatMessagesFilterTypes } from "@common/Types";
import { showErrorToast } from "@common/Utils";

type Params = ChatMessagesFilterTypes & {
  enabled?: boolean;
};

export const useGetRidersChatListDetail = (filters?: Params) => {
  const { enabled = true, search = "", ...otherFilter } = filters ?? {};

  const { isError, error, data, ...query } = useQuery<
    AxiosResponse<ChatListTypes>
  >({
    queryKey: [QUERY_KEYS.SUPPORT_RIDERS_CHAT_MESSAGE_LIST_EXACT, filters],
    queryFn: async () =>
      await supportChatService.getRidersMessages<ChatListTypes>({
        search,
        ...otherFilter,
      }),
    enabled: enabled,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
