/**
 * Сервисы для parks
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const parksPath = {
  parks_short: `${src}/parks-short/`,
  parks_applications: `${src}/parks/applications/`,
  parks: `${src}/parks/`,
};
