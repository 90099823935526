import React from "react";

import { isTargetNavi } from "@assets/Flags";

type Props = {
  className?: string;
};

export const AppLandingLogo = ({ className }: Props) => {
  if (isTargetNavi) {
    return (
      <svg
        width="272"
        height="64"
        viewBox="0 0 272 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M93.243 15.1112V51H87.396L69.502 28.1159V51H62.1428V15.1112H67.9394L85.8334 37.9449V15.1112H93.243ZM125.541 25.4444V51H118.031V47.2196C117.258 48.5301 116.166 49.555 114.754 50.2943C113.343 51.0336 111.747 51.4032 109.966 51.4032C107.748 51.4032 105.782 50.8656 104.068 49.7903C102.388 48.7149 101.061 47.186 100.086 45.2034C99.1455 43.1871 98.675 40.8685 98.675 38.2474C98.675 35.6263 99.1455 33.2908 100.086 31.241C101.061 29.1912 102.405 27.6118 104.119 26.5029C105.833 25.3603 107.782 24.7891 109.966 24.7891C111.747 24.7891 113.343 25.1755 114.754 25.9484C116.166 26.7213 117.258 27.763 118.031 29.0736V25.4444H125.541ZM112.184 45.6066C114.066 45.6066 115.51 44.9681 116.519 43.6912C117.527 42.4142 118.031 40.566 118.031 38.1466C118.031 35.7271 117.527 33.8789 116.519 32.602C115.51 31.2914 114.066 30.6361 112.184 30.6361C110.302 30.6361 108.84 31.3082 107.798 32.6524C106.757 33.9965 106.236 35.8615 106.236 38.2474C106.236 40.6332 106.74 42.4646 107.748 43.7416C108.79 44.9849 110.268 45.6066 112.184 45.6066ZM156.888 25.4948L145.647 51H139.195L128.106 25.4948H136.07L142.623 42.0278L149.377 25.4948H156.888ZM159.398 25.4444H167.009V51H159.398V25.4444ZM167.261 13.4478V20.6054H159.146V13.4478H167.261Z"
          fill="white"
        />
        <path
          d="M182.001 21.5127H170.76V15.1112H201.205V21.5127H189.965V51H182.001V21.5127ZM226.402 25.4444V51H218.891V47.2196C218.118 48.5301 217.026 49.555 215.615 50.2943C214.204 51.0336 212.607 51.4032 210.826 51.4032C208.608 51.4032 206.643 50.8656 204.929 49.7903C203.249 48.7149 201.921 47.186 200.947 45.2034C200.006 43.1871 199.535 40.8685 199.535 38.2474C199.535 35.6263 200.006 33.2908 200.947 31.241C201.921 29.1912 203.265 27.6118 204.979 26.5029C206.693 25.3603 208.642 24.7891 210.826 24.7891C212.607 24.7891 214.204 25.1755 215.615 25.9484C217.026 26.7213 218.118 27.763 218.891 29.0736V25.4444H226.402ZM213.044 45.6066C214.926 45.6066 216.371 44.9681 217.379 43.6912C218.387 42.4142 218.891 40.566 218.891 38.1466C218.891 35.7271 218.387 33.8789 217.379 32.602C216.371 31.2914 214.926 30.6361 213.044 30.6361C211.162 30.6361 209.701 31.3082 208.659 32.6524C207.617 33.9965 207.096 35.8615 207.096 38.2474C207.096 40.6332 207.6 42.4646 208.608 43.7416C209.65 44.9849 211.129 45.6066 213.044 45.6066ZM258.655 51H249.683L243.483 43.1871L237.283 51H228.362L239.148 37.8441L229.017 25.4444H237.989L243.483 32.5515L248.977 25.4444H258L247.869 37.8441L258.655 51ZM260.603 25.4444H268.214V51H260.603V25.4444ZM268.466 13.4478V20.6054H260.351V13.4478H268.466Z"
          fill="#FFE600"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 25.7877C0 10.7192 11.4981 0.976074 24.2065 0.976074C36.9148 0.976074 48.4129 10.7192 48.4129 25.7877C48.4129 35.8334 40.334 47.7248 24.2065 61.4922C8.0789 47.7248 0 35.8334 0 25.7877ZM12.6786 21.1489H4.94006V28.8875H12.6786V21.1489ZM20.4172 28.8866H20.4173V28.887H20.4172V28.8866ZM20.4172 28.8866H12.6787V36.6251H20.4173V28.887H28.1558V21.1484H20.4172V28.8866ZM28.1559 28.8866H35.8945L35.8945 21.1484H43.6331V28.887H35.8945L35.8945 36.6251H28.1559V28.8866Z"
          fill="white"
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="146"
      height="29"
      viewBox="0 0 146 29"
      fill="white"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.903 26.2073C113.903 26.2073 110.308 20.6405 110.308 15.5914C110.308 10.8466 113.211 8.79445 114.953 8.6095C116.52 8.4431 118.343 8.51545 119.782 9.816C121.705 11.5544 122.079 15.2879 119.782 15.5782C117.368 15.8833 117.329 13.1468 117.329 13.1468C115.421 11.6328 112.115 16.6952 116.977 19.7339C120.313 21.8185 123.306 19.964 124.609 19.0435C125.912 19.964 128.905 21.8185 132.24 19.7339C137.103 16.6952 133.796 11.6328 131.888 13.1468C131.888 13.1468 131.849 15.8833 129.436 15.5782C127.138 15.2879 127.513 11.5544 129.436 9.816C130.874 8.51545 132.698 8.4431 134.265 8.6095C136.006 8.79445 138.909 10.8466 138.909 15.5914C138.909 20.6405 135.314 26.2073 135.314 26.2073C135.314 26.2073 139.657 30.5504 142.573 26.1778C145.488 21.8051 146.448 12.2062 141.716 7.07624C136.197 1.09263 130.128 3.70087 127.138 5.23513C126.994 3.10085 126.219 2.01318 124.609 2.01318C122.999 2.01318 122.224 3.10085 122.079 5.23513C119.09 3.70087 113.02 1.09263 107.501 7.07624C102.77 12.2062 103.729 21.8051 106.645 26.1778C109.561 30.5504 113.903 26.2073 113.903 26.2073Z"
        fill="url(#paint0_linear_1126_1058)"
      />
      <path d="M86.2866 25.9435V7.71481H91.2306V25.9435H86.2866ZM91.2306 15.8668L89.3059 14.5836C89.5323 12.3443 90.1865 10.5831 91.2684 9.29991C92.3503 7.99157 93.9102 7.3374 95.9482 7.3374C96.8288 7.3374 97.6214 7.48837 98.3259 7.79029C99.0304 8.06705 99.672 8.53252 100.251 9.18669L97.1559 12.7343C96.8792 12.4324 96.5395 12.2059 96.1369 12.055C95.7595 11.904 95.3192 11.8285 94.816 11.8285C93.7593 11.8285 92.8912 12.1682 92.2119 12.8475C91.5577 13.5017 91.2306 14.5081 91.2306 15.8668Z" />
      <path d="M74.876 26.3208C73.0393 26.3208 71.3787 25.9057 69.8943 25.0754C68.435 24.2199 67.265 23.0751 66.3844 21.641C65.5289 20.1817 65.1012 18.5588 65.1012 16.7725C65.1012 14.9861 65.5289 13.3884 66.3844 11.9794C67.2398 10.5453 68.4098 9.41304 69.8943 8.58274C71.3787 7.72729 73.0267 7.29956 74.8383 7.29956C76.7001 7.29956 78.3607 7.72729 79.82 8.58274C81.3045 9.41304 82.4745 10.5453 83.3299 11.9794C84.1854 13.3884 84.6131 14.9861 84.6131 16.7725C84.6131 18.5588 84.1854 20.1817 83.3299 21.641C82.4745 23.0751 81.3045 24.2199 79.82 25.0754C78.3607 25.9057 76.7127 26.3208 74.876 26.3208ZM74.8383 21.7165C75.7692 21.7165 76.5869 21.5152 77.2914 21.1126C78.0211 20.6849 78.5746 20.1062 78.952 19.3766C79.3546 18.6217 79.5559 17.7663 79.5559 16.8102C79.5559 15.8541 79.3546 15.0112 78.952 14.2816C78.5494 13.5519 77.9959 12.9858 77.2914 12.5832C76.5869 12.1555 75.7692 11.9417 74.8383 11.9417C73.9325 11.9417 73.1274 12.1555 72.4229 12.5832C71.7184 12.9858 71.1649 13.5519 70.7623 14.2816C70.3597 15.0112 70.1584 15.8541 70.1584 16.8102C70.1584 17.7663 70.3597 18.6217 70.7623 19.3766C71.1649 20.1062 71.7184 20.6849 72.4229 21.1126C73.1274 21.5152 73.9325 21.7165 74.8383 21.7165Z" />
      <path d="M60.1893 26.3209C58.881 26.3209 57.711 26.0567 56.6794 25.5283C55.673 25 54.8679 24.2326 54.264 23.2262C53.6853 22.2197 53.396 20.9995 53.396 19.5653V0.128906H58.3778V19.5653C58.3778 20.295 58.5916 20.8485 59.0193 21.2259C59.4471 21.6033 59.9503 21.792 60.529 21.792C61.007 21.792 61.4222 21.7165 61.7744 21.5656C62.1267 21.3895 62.4537 21.1504 62.7557 20.8485L65.7749 23.8678C65.0704 24.6729 64.2905 25.2893 63.435 25.717C62.6047 26.1196 61.5228 26.3209 60.1893 26.3209ZM49.2068 12.1304V7.71477H64.6427V12.1304H49.2068Z" />
      <path d="M39.7863 26.3208C37.9496 26.3208 36.289 25.9057 34.8045 25.0754C33.3452 24.2199 32.1753 23.0751 31.2947 21.641C30.4392 20.1817 30.0115 18.5588 30.0115 16.7725C30.0115 14.9861 30.4392 13.3884 31.2947 11.9794C32.1501 10.5453 33.3201 9.41304 34.8045 8.58274C36.289 7.72729 37.937 7.29956 39.7486 7.29956C41.6104 7.29956 43.271 7.72729 44.7303 8.58274C46.2148 9.41304 47.3847 10.5453 48.2402 11.9794C49.0956 13.3884 49.5234 14.9861 49.5234 16.7725C49.5234 18.5588 49.0956 20.1817 48.2402 21.641C47.3847 23.0751 46.2148 24.2199 44.7303 25.0754C43.271 25.9057 41.623 26.3208 39.7863 26.3208ZM39.7486 21.7165C40.6795 21.7165 41.4972 21.5152 42.2017 21.1126C42.9313 20.6849 43.4849 20.1062 43.8623 19.3766C44.2648 18.6217 44.4661 17.7663 44.4661 16.8102C44.4661 15.8541 44.2648 15.0112 43.8623 14.2816C43.4597 13.5519 42.9062 12.9858 42.2017 12.5832C41.4972 12.1555 40.6795 11.9417 39.7486 11.9417C38.8428 11.9417 38.0376 12.1555 37.3332 12.5832C36.6287 12.9858 36.0751 13.5519 35.6726 14.2816C35.27 15.0112 35.0687 15.8541 35.0687 16.8102C35.0687 17.7663 35.27 18.6217 35.6726 19.3766C36.0751 20.1062 36.6287 20.6849 37.3332 21.1126C38.0376 21.5152 38.8428 21.7165 39.7486 21.7165Z" />
      <path d="M0 25.9435V7.71481H4.94402V25.9435H0ZM11.8883 25.9435V15.1875C11.8883 14.1307 11.5486 13.313 10.8693 12.7343C10.2151 12.1305 9.39741 11.8285 8.41616 11.8285C7.76199 11.8285 7.17072 11.9669 6.64235 12.2437C6.11398 12.4953 5.69883 12.8727 5.39691 13.3759C5.09498 13.8791 4.94402 14.483 4.94402 15.1875L3.01925 14.2439C3.01925 12.835 3.32117 11.6147 3.92502 10.5831C4.55403 9.55152 5.39691 8.75897 6.45365 8.20544C7.51038 7.62675 8.7055 7.3374 10.039 7.3374C11.297 7.3374 12.4418 7.62675 13.4734 8.20544C14.505 8.75897 15.3227 9.55152 15.9265 10.5831C16.5304 11.5895 16.8323 12.8098 16.8323 14.2439V25.9435H11.8883ZM23.7766 25.9435V15.1875C23.7766 14.1307 23.4369 13.313 22.7576 12.7343C22.1034 12.1305 21.2857 11.8285 20.3045 11.8285C19.6754 11.8285 19.0842 11.9669 18.5306 12.2437C18.0023 12.4953 17.5871 12.8727 17.2852 13.3759C16.9833 13.8791 16.8323 14.483 16.8323 15.1875L14.0018 14.5081C14.1024 13.0488 14.4924 11.7908 15.1717 10.7341C15.8511 9.65216 16.7443 8.82187 17.8513 8.24318C18.9584 7.63933 20.1912 7.3374 21.5499 7.3374C22.9086 7.3374 24.1288 7.62675 25.2107 8.20544C26.2926 8.75897 27.1481 9.5641 27.7771 10.6208C28.4061 11.6776 28.7206 12.9356 28.7206 14.3949V25.9435H23.7766Z" />
      <defs>
        <linearGradient
          id="paint0_linear_1126_1058"
          x1="124.609"
          y1="2.01318"
          x2="124.609"
          y2="28.1289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73ECFC" />
          <stop offset="1" stopColor="#11C7E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MainLogo = ({ className }: Props) => {
  return (
    <svg
      width="150"
      viewBox="0 0 256 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M77.2431 15.1112V51H71.396L53.502 28.1159V51H46.1428V15.1112H51.9394L69.8334 37.9449V15.1112H77.2431ZM107.541 25.4444V51H100.031V47.2196C99.2579 48.5301 98.1658 49.555 96.7544 50.2943C95.3431 51.0336 93.7469 51.4032 91.9659 51.4032C89.7481 51.4032 87.7822 50.8656 86.0684 49.7903C84.3883 48.7149 83.0609 47.186 82.0864 45.2034C81.1455 43.1871 80.675 40.8685 80.675 38.2474C80.675 35.6263 81.1455 33.2908 82.0864 31.241C83.0609 29.1912 84.4051 27.6118 86.1189 26.5029C87.8326 25.3603 89.7817 24.7891 91.9659 24.7891C93.7469 24.7891 95.3431 25.1755 96.7544 25.9484C98.1658 26.7213 99.2579 27.763 100.031 29.0736V25.4444H107.541ZM94.1838 45.6066C96.0656 45.6066 97.5105 44.9681 98.5186 43.6912C99.5267 42.4142 100.031 40.566 100.031 38.1466C100.031 35.7271 99.5267 33.8789 98.5186 32.602C97.5105 31.2914 96.0656 30.6361 94.1838 30.6361C92.3019 30.6361 90.8402 31.3082 89.7985 32.6524C88.7567 33.9965 88.2359 35.8615 88.2359 38.2474C88.2359 40.6332 88.7399 42.4646 89.7481 43.7416C90.7898 44.9849 92.2683 45.6066 94.1838 45.6066ZM136.888 25.4948L125.647 51H119.195L108.106 25.4948H116.07L122.623 42.0278L129.377 25.4948H136.888ZM137.398 25.4444H145.009V51H137.398V25.4444ZM145.261 13.4478V20.6054H137.146V13.4478H145.261Z"
        fill="white"
      />
      <path
        d="M158.001 21.5127H146.76V15.1112H177.205V21.5127H165.965V51H158.001V21.5127ZM200.402 25.4444V51H192.891V47.2196C192.118 48.5301 191.026 49.555 189.615 50.2943C188.204 51.0336 186.607 51.4032 184.826 51.4032C182.609 51.4032 180.643 50.8656 178.929 49.7903C177.249 48.7149 175.921 47.186 174.947 45.2034C174.006 43.1871 173.536 40.8685 173.536 38.2474C173.536 35.6263 174.006 33.2908 174.947 31.241C175.921 29.1912 177.266 27.6118 178.979 26.5029C180.693 25.3603 182.642 24.7891 184.826 24.7891C186.607 24.7891 188.204 25.1755 189.615 25.9484C191.026 26.7213 192.118 27.763 192.891 29.0736V25.4444H200.402ZM187.044 45.6066C188.926 45.6066 190.371 44.9681 191.379 43.6912C192.387 42.4142 192.891 40.566 192.891 38.1466C192.891 35.7271 192.387 33.8789 191.379 32.602C190.371 31.2914 188.926 30.6361 187.044 30.6361C185.162 30.6361 183.701 31.3082 182.659 32.6524C181.617 33.9965 181.096 35.8615 181.096 38.2474C181.096 40.6332 181.6 42.4646 182.609 43.7416C183.65 44.9849 185.129 45.6066 187.044 45.6066ZM230.655 51H221.683L215.483 43.1871L209.283 51H200.362L211.148 37.8441L201.017 25.4444H209.989L215.483 32.5515L220.977 25.4444H230L219.869 37.8441L230.655 51ZM230.603 25.4444H238.214V51H230.603V25.4444ZM238.466 13.4478V20.6054H230.351V13.4478H238.466Z"
        fill="#FFE500"
      />
      <path
        d="M19.0065 8C9.02807 8 0 15.6501 0 27.4816C0 35.3693 6.3434 44.7062 19.0065 55.5161C31.6695 44.7062 38.0129 35.3693 38.0129 27.4816C38.0129 15.6501 28.9848 8 19.0065 8Z"
        fill="white"
      />
      <rect
        x="3.87885"
        y="23.8394"
        width="6.07619"
        height="6.07619"
        fill="#3065ED"
      />
      <rect
        x="9.95512"
        y="29.9148"
        width="6.07619"
        height="6.07619"
        fill="#3065ED"
      />
      <rect
        x="16.0312"
        y="23.8389"
        width="6.07619"
        height="6.07619"
        fill="#3065ED"
      />
      <rect
        x="22.1075"
        y="29.9148"
        width="6.07619"
        height="6.07619"
        fill="#3065ED"
      />
      <rect
        x="28.1837"
        y="23.8389"
        width="6.07619"
        height="6.07619"
        fill="#3065ED"
      />
    </svg>
  );
};
