import clsx from "clsx";
import { isToday } from "date-fns";
import React from "react";

import { useSelectedChat } from "@common/Hooks";
import { ChatListItemTypes, SelectedChatType } from "@common/Types";
import { formatDate } from "@common/Utils";
import { Col, Row, Typography } from "@components/Elements";

type Props = {
  data: ChatListItemTypes;
  curItem: SelectedChatType | null;
  handleSelectChat: (item: ChatListItemTypes) => void;
};

export const MemberComponentItem = ({
  data,
  curItem,
  handleSelectChat,
}: Props) => {
  const { selectedChat } = useSelectedChat();

  const {
    id,
    phone = "",
    first_name = "",
    last_name = "",
    last_message,
    is_read_admin,
    updated_at,
  } = data;

  const dialogDate = formatDate(
    updated_at,
    `${isToday(new Date(updated_at)) ? "HH:mm" : "MM-dd-yyyy HH:mm"}`,
  );
  const fullName =
    first_name || last_name ? `${first_name} ${last_name}` : "Не указано";

  return (
    <Col
      key={`${id}${updated_at}`}
      onClick={() => handleSelectChat(data)}
      className={clsx(
        "cursor-pointer border-b border-gray-100 p-[8px] w-full gap-[4px] mt-[4px] mb-[4px]",
        {
          "bg-neutral-bg-75 rounded-[4px]": phone === curItem?.phone,
        },
      )}
    >
      <Row className="w-full flex-nowrap" justifyContent="justify-between">
        <Typography
          variant="body-2-b"
          color="neutral-800"
          className="font-bold"
        >
          {fullName}
        </Typography>
        <Typography variant="caption-a" className="text-gray-400">
          {dialogDate}
        </Typography>
      </Row>
      <Row className="w-full flex-nowrap" justifyContent="justify-between">
        <Typography
          variant="caption-a"
          color="neutral-500"
          className="truncate w-9/12"
        >
          {last_message ?? "Прикрепленное изображение"}
        </Typography>
        {!is_read_admin && id !== selectedChat?.id && (
          <div className="w-3 h-3 border rounded-full self-center bg-brand-bg-800" />
        )}
      </Row>
    </Col>
  );
};
