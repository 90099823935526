import { SegmentedControlItem } from "@mantine/core";

export interface Additional {
  driver_profile_bonus_view: string;
  driver_profile_bonus_update: string;
  driver_profile_bonus_delete: string;
  driver_profile_balance_view: string;
  driver_profile_balance_update: string;
  driver_profile_balance_delete: string;
  admins_create: string;
  parks_create: string;
  city_create: string;
  city_edit: string;
  city_delete: string;
}

export const ADDITIONAL: Additional = {
  driver_profile_bonus_view: "driver_profile_bonus_view",
  driver_profile_bonus_update: "driver_profile_bonus_update",
  driver_profile_bonus_delete: "driver_profile_bonus_delete",
  driver_profile_balance_view: "driver_profile_balance_view",
  driver_profile_balance_update: "driver_profile_balance_update",
  driver_profile_balance_delete: "driver_profile_balance_delete",

  admins_create: "admins_create",
  parks_create: "parks_create",
  city_create: "city_create",
  city_edit: "city_edit",
  city_delete: "city_delete",
};

interface DRIVER_APPLICATION_TABS_TYPES {
  main_tabs: SegmentedControlItem[];
  registration_tabs: SegmentedControlItem[];
  change_car_tabs: SegmentedControlItem[];
  landing_tabs: SegmentedControlItem[];
}

export const DRIVER_APPLICATION_TABS: DRIVER_APPLICATION_TABS_TYPES = {
  main_tabs: [
    { value: "driver_registration_application", label: "Регистрация" },
    { value: "driver_auto_application", label: "Смена авто" },
    { value: "landing", label: "Лендинг" },
  ],
  registration_tabs: [
    { value: "driver_registration_new_application", label: "Новые" },
    { value: "driver_registration_declined_application", label: "Отклоненные" },
    { value: "driver_registration_approved_application", label: "Одобренные" },
  ],
  change_car_tabs: [
    { value: "driver_auto_new_application", label: "Новые" },
    { value: "driver_auto_declined_application", label: "Отклоненные" },
    { value: "driver_auto_approved_application", label: "Одобренные" },
  ],
  landing_tabs: [
    { value: "new_application", label: "Новые" },
    { value: "read_application", label: "Прочитанные" },
  ],
};

export const {
  driver_profile_bonus_view,
  driver_profile_bonus_update,
  driver_profile_bonus_delete,
  driver_profile_balance_view,
  driver_profile_balance_update,
  driver_profile_balance_delete,
  admins_create,
} = ADDITIONAL;
