import { ColorLevels, ColorVariants } from "@common/Types";

// Create a union type for the keys of colorClasses
export type ColorClassKey = `${ColorVariants}-${ColorLevels}`;

// Define the colorClasses object with the correct type
export const colorClasses: { [key in ColorClassKey]: string } = {
  // Neutral
  "neutral-0": "text-neutral-text-0",
  "neutral-50": "text-neutral-text-50",
  "neutral-75": "text-neutral-text-75",
  "neutral-100": "text-neutral-text-100",
  "neutral-200": "text-neutral-text-200",
  "neutral-300": "text-neutral-text-300",
  "neutral-400": "text-neutral-text-400",
  "neutral-500": "text-neutral-text-500",
  "neutral-600": "text-neutral-text-600",
  "neutral-700": "text-neutral-text-700",
  "neutral-800": "text-neutral-text-800",
  "neutral-900": "text-neutral-text-900",
  "neutral-1000": "text-neutral-text-1000",

  // Brand
  "brand-0": "text-brand-text-0",
  "brand-50": "text-brand-text-50",
  "brand-75": "text-brand-text-75",
  "brand-100": "text-brand-text-100",
  "brand-200": "text-brand-text-200",
  "brand-300": "text-brand-text-300",
  "brand-400": "text-brand-text-400",
  "brand-500": "text-brand-text-500",
  "brand-600": "text-brand-text-600",
  "brand-700": "text-brand-text-700",
  "brand-800": "text-brand-text-800",
  "brand-900": "text-brand-text-900",
  "brand-1000": "text-brand-text-1000",

  // Attentive
  "attentive-0": "text-attentive-text-0",
  "attentive-50": "text-attentive-text-50",
  "attentive-75": "text-attentive-text-75",
  "attentive-100": "text-attentive-text-100",
  "attentive-200": "text-attentive-text-200",
  "attentive-300": "text-attentive-text-300",
  "attentive-400": "text-attentive-text-400",
  "attentive-500": "text-attentive-text-500",
  "attentive-600": "text-attentive-text-600",
  "attentive-700": "text-attentive-text-700",
  "attentive-800": "text-attentive-text-800",
  "attentive-900": "text-attentive-text-900",
  "attentive-1000": "text-attentive-text-1000",

  // Positive
  "positive-0": "text-positive-text-0",
  "positive-50": "text-positive-text-50",
  "positive-75": "text-positive-text-75",
  "positive-100": "text-positive-text-100",
  "positive-200": "text-positive-text-200",
  "positive-300": "text-positive-text-300",
  "positive-400": "text-positive-text-400",
  "positive-500": "text-positive-text-500",
  "positive-600": "text-positive-text-600",
  "positive-700": "text-positive-text-700",
  "positive-800": "text-positive-text-800",
  "positive-900": "text-positive-text-900",
  "positive-1000": "text-positive-text-1000",

  // Negative
  "negative-0": "text-negative-text-0",
  "negative-50": "text-negative-text-50",
  "negative-75": "text-negative-text-75",
  "negative-100": "text-negative-text-100",
  "negative-200": "text-negative-text-200",
  "negative-300": "text-negative-text-300",
  "negative-400": "text-negative-text-400",
  "negative-500": "text-negative-text-500",
  "negative-600": "text-negative-text-600",
  "negative-700": "text-negative-text-700",
  "negative-800": "text-negative-text-800",
  "negative-900": "text-negative-text-900",
  "negative-1000": "text-negative-text-1000",
};
