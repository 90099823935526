import { TOKEN } from "@common/Constants";

import { LocalStorageReturnType } from "./user-localstorage";

export const getAccessTokenLocalStorage = (): LocalStorageReturnType => {
  return localStorage.getItem("access_token");
};

export const getRefreshTokenLocalStorage = (): LocalStorageReturnType => {
  return localStorage.getItem("refresh_token");
};

export const removeToken = (): void => {
  localStorage.removeItem("access_token");
};

export const removeRefreshToken = (): void => {
  localStorage.removeItem("refresh_token");
};

export const setTokenLocalStorage = (token: string): void => {
  localStorage.setItem(TOKEN.ACCESS_TOKEN, token);
};

export const setRefreshTokenLocalStorage = (refreshToken: string): void => {
  localStorage.setItem(TOKEN.REFRESH_TOKEN, refreshToken);
};
