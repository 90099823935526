import { Group, SegmentedControl } from "@mantine/core";
import React from "react";

import { AUDIENCE_FILTERS } from "@common/Constants";
import { PromotionAudienceType } from "@common/Types";
import { Typography } from "@components/Elements";

type Props = {
  filterValue?: PromotionAudienceType;
  setFilterValue: (value: PromotionAudienceType) => void;
};

export const AudienceFilter = ({ filterValue, setFilterValue }: Props) => {
  return (
    <Group spacing={"xs"} direction="column">
      <Typography
        variant={"body-1-a"}
        color="neutral-900"
        className={"font-bold"}
      >
        Аудитория
      </Typography>

      <div className={`inline-flex items-center`}>
        <SegmentedControl
          size={"sm"}
          className={"z-0"}
          value={filterValue}
          onChange={setFilterValue}
          data={AUDIENCE_FILTERS}
        />
      </div>
    </Group>
  );
};
