import { Tabs as MantineTabs } from "@mantine/core";
import React from "react";

import { TABS_VIEW_CLASSES } from "./variants";

type TabsType = {
  label: string;
  content: React.ReactNode;
};
type Props = {
  activeTab: number;
  setActiveTab: (index: number) => void;
  tabs: TabsType[];
  view?: string;
};

export const Tabs = ({
  activeTab,
  setActiveTab,
  tabs,
  view = "default",
}: Props) => {
  return (
    <MantineTabs
      active={activeTab}
      onTabChange={setActiveTab}
      classNames={TABS_VIEW_CLASSES[view]}
    >
      {tabs.map((tab, index) => (
        <MantineTabs.Tab key={index} label={tab.label}>
          {tab.content}
        </MantineTabs.Tab>
      ))}
    </MantineTabs>
  );
};
