import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { supportChatService } from "@api/services";
import { ChatMessageFilterTypes, ChatMessageTypes } from "@common/Types";
import { showErrorToast } from "@common/Utils";

type FilterTypes = ChatMessageFilterTypes & {
  enabled?: boolean;
};

export const useGetRidersMessagesById = (
  id: string,
  filters: FilterTypes = {},
) => {
  const { enabled = true, ...otherFilter } = filters;

  const { isError, error, data, ...query } = useQuery<
    AxiosResponse<ChatMessageTypes>
  >({
    queryKey: [QUERY_KEYS.SUPPORT_RIDERS_CHAT_MESSAGE_LIST_BY_ID, id, filters],
    queryFn: async () =>
      await supportChatService.getRidersMessagesById(id, otherFilter),
    enabled: enabled && !!id,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
