import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { FRAUD_STATUSES } from "@common/Constants";
import { VerdicAniFraudsQueryParams } from "@common/Types";
import { showErrorToast } from "@common/Utils";

type Params = VerdicAniFraudsQueryParams;

export const useVerdictAntiFrauds = () => {
  const queryClient = useQueryClient();

  const { isError, error, ...query } = useMutation({
    mutationFn: async (params: Params) =>
      await antiFraudsService.verdictAntiFraud(params),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANTI_FRAUDS] });

      if (variables.type === FRAUD_STATUSES.UNLOCKED) {
        toast.success("Вы одобрили поездку");
      }
      if (variables.type === FRAUD_STATUSES.BLOCKED) {
        toast.success("Вы отклонили поездку");
      }
    },
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  return { isError, error, ...query };
};
