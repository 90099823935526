/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from "react";

/**
 * A custom hook that delays the execution of an async callback function with parameters.
 *
 * @param handler - The async callback function to be executed after the delay.
 * @param delay - The delay in milliseconds before invoking the callback.
 * @returns A function to start the delay with optional parameters.
 */
export function useDelay<T>(
  handler: (params?: any) => Promise<T>,
  delay: number,
): (params?: any) => void {
  const handlerRef = useRef(handler);

  handlerRef.current = handler;

  const startDelay = useCallback(
    (params?: any) => {
      const timer = setTimeout(async () => {
        try {
          await handlerRef.current(params);
        } catch (error) {
          console.error("Error executing handler:", error);
        }
      }, delay);

      return () => clearTimeout(timer);
    },
    [delay],
  );

  return startDelay;
}
