import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { ParkParams, ParksType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useGetParks = ({ limit, skip, search }: ParkParams) => {
  const { isError, error, data, ...query } = useQuery<AxiosResponse<ParksType>>(
    {
      queryKey: [QUERY_KEYS.PARKS, limit, skip, search],
      queryFn: async () => await parksService.getParks({ limit, skip, search }),
    },
  );

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
