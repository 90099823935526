import React from "react";

type Props = {
  page?: boolean;
  activePage?: boolean;
};

export const EmptyPageIcon = ({ page, activePage }: Props) => {
  return (
    <svg
      width="231"
      height="150"
      viewBox="0 0 231 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current text-gray-400 ${
        page === activePage && "text-indigo-500"
      }`}
    >
      <mask
        id="mask0_2123_3204"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="231"
        height="150"
      >
        <rect
          x="0.5"
          width="230"
          height="150"
          rx="12"
          fill="url(#paint0_linear_2123_3204)"
        />
        <rect
          x="0.5"
          width="230"
          height="150"
          rx="12"
          fill="url(#paint1_linear_2123_3204)"
          fillOpacity="0.2"
        />
      </mask>
      <g mask="url(#mask0_2123_3204)">
        <rect
          opacity="0.08"
          x="0.5"
          width="230"
          height="150"
          rx="12"
          fill="#413D6B"
        />
        <g filter="url(#filter0_d_2123_3204)">
          <rect x="12.5" y="12" width="206" height="24" rx="4" fill="white" />
          <rect
            opacity="0.08"
            x="20.5"
            y="19.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="84.833"
            y="19.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="149.167"
            y="19.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect x="12.5" y="40" width="206" height="24" rx="4" fill="white" />
          <rect
            opacity="0.08"
            x="20.5"
            y="47.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="84.833"
            y="47.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="149.167"
            y="47.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect x="12.5" y="68" width="206" height="24" rx="4" fill="white" />
          <rect
            opacity="0.08"
            x="20.5"
            y="75.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="84.833"
            y="75.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="149.167"
            y="75.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect x="12.5" y="96" width="206" height="24" rx="4" fill="white" />
          <rect
            opacity="0.08"
            x="20.5"
            y="103.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="84.833"
            y="103.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="149.167"
            y="103.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect x="12.5" y="124" width="206" height="24" rx="4" fill="white" />
          <rect
            opacity="0.08"
            x="20.5"
            y="131.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="84.833"
            y="131.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
          <rect
            opacity="0.08"
            x="149.167"
            y="131.5"
            width="61.3333"
            height="9"
            rx="2"
            fill="#413D6B"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2123_3204"
          x="-5.5"
          y="-6"
          width="242"
          height="172"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="9" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2123_3204"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2123_3204"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2123_3204"
          x1="6"
          y1="-3"
          x2="7"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222222" stopOpacity="0.88" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2123_3204"
          x1="103.5"
          y1="-20"
          x2="101"
          y2="175.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
