import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { tariffsService } from "@api/services";
import { TariffType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useGetTariffs = () => {
  const { isError, error, data, refetch, ...query } = useQuery<
    AxiosResponse<TariffType[]>
  >({
    queryKey: [QUERY_KEYS.TARIFFS],
    queryFn: async () => await tariffsService.getTariffs(),
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, refetch, ...query };
};
