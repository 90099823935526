import { Button, Chip, Chips, Group, Modal, Space, Title } from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import React, { useState } from "react";

import { formatOfDate } from "@common/Constants";
import { useDimension } from "@common/Hooks";
import { DateRageFilterType } from "@common/Types";
import { formatDate, formatDateRange } from "@common/Utils";

type Props = {
  filterValue: DateRageFilterType;
  setFilterValue: (value: DateRageFilterType) => void;
};

const stringToDate = (
  dateRangeString: DateRageFilterType,
): [Date | null, Date | null] => {
  let startDate;
  let endDate;
  if (dateRangeString[0]) {
    startDate = new Date(dateRangeString[0]);
  } else {
    startDate = null;
  }

  if (dateRangeString[1]) {
    endDate = new Date(dateRangeString[1]);
  } else {
    endDate = null;
  }

  return [startDate, endDate];
};

export const DateRangeFilter = ({ filterValue, setFilterValue }: Props) => {
  const { isDesktop } = useDimension();
  const [chosenRange, setChosenRange] = useState("today");
  const [opened, setOpened] = useState(false);

  const onChange = (range: string) => {
    if (range === "custom") {
      setOpened(true);
      setChosenRange(range);
      return;
    } else {
      setChosenRange(range);
      setFilterValue(formatDateRange(range));
    }
  };

  const onCustomChange = (value: [Date, Date]) => {
    if (value.every((date) => date)) {
      setFilterValue([
        formatDate(value[0], formatOfDate),
        formatDate(value[1], formatOfDate),
      ]);
      setOpened(false);
    }
  };

  return (
    <Group spacing={"xs"} direction="column">
      <Title order={5}>Период</Title>
      <Chips value={chosenRange} onChange={onChange} spacing={"sm"}>
        <Chip value="today">Сегодня</Chip>
        <Chip value="yesterday">Вчера</Chip>
        <Chip value="week">Неделя</Chip>
        <Chip value="month">Месяц</Chip>
        <Chip value="all">Все время</Chip>
        <Chip value="custom" onClick={() => setOpened(true)}>
          Выбрать
        </Chip>
      </Chips>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={"Выберите промежуток"}
        className={"justify-center"}
        size={"lg"}
        padding={"xl"}
      >
        <RangeCalendar
          locale="ru"
          // withSelect
          nextMonthLabel="Следующий месяц"
          previousMonthLabel="Предыдущий месяц"
          amountOfMonths={isDesktop ? 2 : 1}
          value={stringToDate(filterValue)}
          onChange={onCustomChange}
          size={"md"}
          fullWidth
          allowSingleDateInRange
        />
        <Space />
        <Group position={"right"}>
          <Button onClick={() => setOpened(false)}>Закрыть</Button>
        </Group>
      </Modal>
    </Group>
  );
};
