import { CaretDown } from "phosphor-react";
import React from "react";

import { useGetCities } from "@api/queries";
import { CityIcon } from "@assets/Icons";
import { buildCitiesOption } from "@common/Utils";

import { CheckboxList } from "../checkbox-list";

type Props<Filters> = {
  noTitle?: boolean;
  className?: string;
  filters: Filters;
  setFilters: (value: Filters) => void;
};

export const CitiesFilter = <T,>({
  filters,
  setFilters,
  className,
  noTitle = false,
}: Props<T>) => {
  const { response } = useGetCities(0, 50);

  return (
    <CheckboxList
      icon={CaretDown}
      MainIcon={<CityIcon />}
      title={noTitle ? "" : "Город"}
      filters={filters}
      setFilters={setFilters}
      field={"city_id" as keyof T}
      options={buildCitiesOption(response ?? [])}
      className={className}
      singleChoice
    />
  );
};
