import { Field } from "formik";
import React from "react";

import { LocalFiltersType } from "@common/Types";
import { Typography } from "@components/Elements";

type Props = {
  label?: string;
  field: string;
  array: LocalFiltersType[];
  error: string | undefined;
  touched: boolean | undefined;
  values?: string | number;
  setFieldValue: (field: string, values: string) => void;
  customLabel?: JSX.Element;
  className?: string;
};

export const FormRadio: React.FC<Props> = ({
  label,
  field,
  array,
  error,
  touched,
  values,
  setFieldValue,
  customLabel,
  className,
}) => {
  return (
    <div className={`flex flex-col items-start mt-4`}>
      {customLabel ? (
        customLabel
      ) : (
        <label className="text-caption-b text-neutral-text-800 mb-[4px]">
          {label}
        </label>
      )}
      <div className={`w-full flex items-center justify-between ${className}`}>
        {array &&
          array.map((subscriber) => (
            <div className={"w-full items-center"} key={subscriber.value}>
              <label
                className="mr-4 flex items-center cursor-pointer text-body-1-b"
                key={subscriber.label}
              >
                <Field
                  type="radio"
                  name={field}
                  key={subscriber.value}
                  value={subscriber.value}
                  checked={subscriber.value === values?.toString()}
                  onChange={() => setFieldValue(field, subscriber.value)}
                  className="mr-[8px] form-radio text-center text-brand-bg-700 cursor-pointer focus:ring-1 bg:brand-bg-700 focus:brand-bg-700  w-[18px] h-[18px]"
                />
                {subscriber.label}
              </label>
            </div>
          ))}
      </div>
      {error && touched ? (
        <Typography
          variant={"caption-a"}
          color="negative-500"
          className={"mt-[5px]"}
        >
          {error}
        </Typography>
      ) : null}
    </div>
  );
};
