import { MultiSelect } from "@mantine/core";
import { Field, FormikProps, useField } from "formik";
import { FieldProps } from "formik/dist/Field";
import React from "react";

import { LocalFiltersType } from "@common/Types";
import { Typography } from "@components/Elements";

const CustomMultiSelect = ({
  field,
  form,
  ...props
}: {
  field: FieldProps["field"];
  form: FormikProps<FieldProps>;
  data: LocalFiltersType[];
}) => {
  return (
    <MultiSelect
      name={field.name}
      value={field.value}
      onBlur={field.onBlur}
      onChange={(values) => form.setFieldValue(field.name, values)}
      {...props}
    />
  );
};

type Props = {
  name: string;
  label: string;
  placeholder: string;
  array: LocalFiltersType[];
  error: string | string[] | undefined;
};
export const FormMultiSelect: React.FC<Props> = ({
  name,
  label,
  placeholder,
  array,
  error,
}) => {
  const [, meta, helpers] = useField(name);
  const hasError = meta.touched && error;

  return (
    <div className="flex flex-col items-start justify-start space-y-1 w-full relative">
      <label
        htmlFor={name}
        className="text-neutral-text-800 text-caption-b font-medium"
      >
        {label}
      </label>
      <div className="relative w-full">
        <Field
          name={name}
          component={CustomMultiSelect}
          data={array}
          placeholder={placeholder}
          classNames={{
            searchInput: "placeholder:text-body-1-a ring-0 ring-transparent",
            input: `h-[42px] rounded-[4px] border-neutral-border-300 px-[14px]  ${
              hasError
                ? "border-error placeholder:text-error border-negative-bg-500"
                : ""
            }`,
            hovered: "bg-[#E7F5FF]",
            item: "flex items-center text-body-1-a h-[45px] mb-[4px] last:mb-0",
            values: "h-full",
          }}
          onBlur={() => helpers.setTouched(true)}
        />
      </div>
      {hasError ? (
        <Typography
          variant={"caption-a"}
          color="negative-500"
          className={"mt-[5px]"}
        >
          {error}
        </Typography>
      ) : null}
    </div>
  );
};
