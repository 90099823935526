import { clsx } from "clsx";
import { isToday } from "date-fns";
import React, { FC } from "react";

import { ChatMessageType } from "@common/Types";
import { formatDate } from "@common/Utils";
import { Col, Typography } from "@components/Elements";

type Props = {
  message: ChatMessageType;
  prevMessageSender?: string;
};

export const MessageCardText: FC<Props> = ({ message, prevMessageSender }) => {
  const dateFormat = isToday(new Date(message.created_at))
    ? "HH:mm"
    : "MM-dd-yyyy HH:mm";

  return (
    <Col
      className={clsx(
        "p-[12px] inline-block mt-[4px] mb-[4px] rounded-t-[20px] max-w-sm gap-[4px]",
        {
          "bg-brand-bg-800 rounded-bl-[20px] self-end break-words":
            message.sender === "admin",
          "bg-neutral-bg-75 text-left rounded-br-[20px] self-start break-words":
            message.sender !== "admin",
          "rounded-[20px]": prevMessageSender === message.sender,
        },
      )}
    >
      <Typography
        variant="body-2-a"
        color={message.sender !== "admin" ? "neutral-800" : "neutral-0"}
      >
        {message.message}
      </Typography>
      <Typography
        variant="caption-a"
        color={message.sender !== "admin" ? "neutral-500" : "neutral-0"}
      >
        {formatDate(message.created_at, dateFormat)}
      </Typography>
    </Col>
  );
};
