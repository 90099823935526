import { CloseButton } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

import { useGetDrivers } from "@api/queries";
import { buildDriversOption } from "@common/Utils";
import { AutoCompleteInput, OptionItem } from "@components/Shared";

type Props = {
  name: string;
  label: string;
  placeholder: string;
};

export const DriverAutocompleteField = ({
  name,
  label,
  placeholder,
}: Props) => {
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 350);

  const { response } = useGetDrivers({
    limit: 15,
    skip: 0,
    search: debouncedSearchString,
  });
  const [selectedData, setSelectedData] = React.useState<OptionItem[]>([]);
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  const options = buildDriversOption(response?.items);

  const filteredOptions = options.filter((item) => {
    return !selectedData.find((data) => data.value === item.value);
  });

  useEffect(() => {
    setValue(selectedData.map((it) => it.value));
  }, [selectedData]);

  const handleSelectOption = (item: OptionItem) => {
    setSelectedData((prevObject) => [...prevObject, item]);
    setSearchString("");
  };

  const handleChange = (value: string) => {
    setSearchString(value);
  };

  const handleDelete = (optionId: string) => {
    setSelectedData((data) => data.filter(({ value }) => value !== optionId));
  };

  const hasError = meta.touched && meta.error;

  return (
    <div className={"flex flex-col"}>
      <AutoCompleteInput
        name={name}
        value={searchString}
        data={filteredOptions}
        onChange={handleChange}
        field={field}
        hasError={hasError}
        error={meta.error}
        onItemSubmit={(option) => handleSelectOption(option as OptionItem)}
        label={label}
        placeholder={placeholder}
      />
      {selectedData.length > 0 && (
        <div className="flex flex-wrap items-center mt-[8px] gap-[4px]">
          {selectedData.map((item) => {
            return (
              <div
                key={item.value}
                className="flex items-center text-caption-a justify-between bg-neutral-bg-75 rounded-[4px] pl-[8px] pr-[4px] py-[4px] "
              >
                {item.description}
                <CloseButton
                  className={"text-neutral-text-500 ml-[3px]"}
                  onClick={() => handleDelete(item.value)}
                  size={16}
                  aria-label="Close window"
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
