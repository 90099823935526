import React, { useEffect, useState } from "react";

import { ArrowIcon } from "@assets/Icons";
import { FraudControlReasonType } from "@common/Types";
import { formatDate } from "@common/Utils";
import { Button, Loader, Modal, Typography } from "@components/Elements";

type Props = {
  open: boolean;
  data?: FraudControlReasonType[];
  onClose: () => void;
};

export const FraudControlReasonModal = ({ open, data, onClose }: Props) => {
  const [page, setPage] = useState(1);

  const totalPages = data?.length ?? 0;

  useEffect(() => {
    if (!open) return;

    setPage(1);
  }, [open]);

  const onNextPage = () => {
    setPage((prev) => (prev += 1));
  };

  const onPreviousPage = () => {
    setPage((prev) => (prev -= 1));
  };

  return (
    <Modal title="Причина" opened={open} onClose={onClose}>
      <Loader loading={!data?.length} height={80}>
        {data?.map(({ id, admin, created_at, description }, index) => {
          const isCurrentPage = index + 1 === page;

          return (
            isCurrentPage && (
              <div className="mt-4 space-y-4" key={id}>
                <div className="space-y-2">
                  <div className="space-x-1">
                    <Typography variant="body-2-a">
                      {admin?.first_name} {admin?.last_name}
                    </Typography>

                    <Typography variant="caption-a" color="neutral-500">
                      {formatDate(created_at, "dd.MM.yyyy HH:mm")}
                    </Typography>
                  </div>

                  <div className="bg-neutral-bg-75 p-3 rounded-xl rounded-tl-none">
                    <Typography
                      variant="body-1-a"
                      as="p"
                      className="line-clamp-4"
                    >
                      {description}
                    </Typography>
                  </div>
                </div>

                {totalPages > 1 ? (
                  <div className="flex justify-between">
                    <Typography variant="body-2-b">{`${page}/${totalPages}`}</Typography>

                    <div className="flex space-x-1">
                      <Button
                        view="none"
                        onClick={onPreviousPage}
                        disabled={page === 1}
                      >
                        <ArrowIcon rotation="left" />
                      </Button>

                      <Button
                        view="none"
                        onClick={onNextPage}
                        disabled={totalPages === page}
                      >
                        <ArrowIcon rotation="right" />
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            )
          );
        })}
      </Loader>
    </Modal>
  );
};
