/**
 * Сервисы для landing cities
 */

import { appConfig } from "@api/config";

const src = appConfig.api.landing;

export const landingCitiesPath = {
  cities: `${src}/cities/`,
};
