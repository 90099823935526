import React from "react";

export const CitiesIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8047_5646)">
        <path
          d="M15 4.01172V17.4817"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 5.78125V20.0012"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.632 4.10288L20.316 5.66388C20.725 5.79988 21 6.18187 21 6.61287V18.6179C21 19.3609 20.218 19.8449 19.553 19.5119L15.895 17.6829C15.332 17.4009 14.669 17.4009 14.106 17.6829L9.895 19.7889C9.332 20.0709 8.669 20.0709 8.106 19.7889L3.553 17.5129C3.214 17.3429 3 16.9969 3 16.6179V5.27887C3 4.59587 3.669 4.11388 4.316 4.32988L8.367 5.67988C8.778 5.81688 9.221 5.81688 9.632 5.67988L14.367 4.10188C14.778 3.96588 15.222 3.96588 15.632 4.10288V4.10288Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8047_5646">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
