import React from "react";
import { useLocation } from "react-router-dom";

import { useNavigateTo } from "@common/Hooks";
import { supportedLanguages } from "@common/Libs/i18next";

import { ReactComponent as GlobusIcon } from "../../assets/icons/globus.svg";

const availableLanguages = Object.keys(supportedLanguages);

const getCurrentLanguage = (lastSegment: string) => {
  if (availableLanguages.includes(lastSegment)) {
    return lastSegment;
  }
  return "ru";
};

const getNextLanguageTitle = (currentLang: string) => {
  if (currentLang === "ky" || currentLang === "en") {
    return "рус";
  }
  return "кырг";
};

const buildPathname = (pathSegments: string[], currentLang: string) => {
  const segmentsWithoutLang = pathSegments.filter((val) => val !== currentLang);

  if (currentLang === "ky" || currentLang === "en") {
    return `/${segmentsWithoutLang.join("/")}/ru`;
  }

  return `/${segmentsWithoutLang.join("/")}/ky`;
};

export const PrivacyLangSwitcher = () => {
  const { navigateTo } = useNavigateTo();

  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter((val) => !!val);

  const lastSegment = pathSegments.at(-1) as string;

  const handleClick = () => {
    const currentLang = getCurrentLanguage(lastSegment);

    const pathname = buildPathname(pathSegments, currentLang);

    navigateTo(pathname);
  };

  return (
    <div className="flex flex-col">
      <button className={"flex items-center"} onClick={handleClick}>
        <span className={"text-base leading-none"}>
          {getNextLanguageTitle(lastSegment)}
        </span>
        <GlobusIcon className="w-[16px] h-[16px] ml-[5px]" />
      </button>
    </div>
  );
};
