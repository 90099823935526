import React from "react";
import { useLocation } from "react-router-dom";

import { useNavigateTo } from "@common/Hooks";
import { supportedLanguages } from "@common/Libs/i18next";
import { availableLangs, FAQDataType, QuestionType } from "@common/Types";
import { FAQPageBody } from "@components/Shared";

const multiLanguagePaths = Object.keys(supportedLanguages).join("|");

type Props = {
  pageData: FAQDataType;
  basePath: string;
};

const getDataByLang = (data: FAQDataType, lang?: availableLangs) => {
  return data[lang ?? "ru"];
};

const removeLanguageCode = (path: string, lang?: string) => {
  if (lang && path.endsWith(`/${lang}`)) {
    return path.slice(0, -3);
  }
  return path;
};

const buildNextUrl = (currentPath: string, segment: string, lang?: string) => {
  if (lang) {
    return currentPath + segment + `/${lang}`;
  }

  return currentPath + segment;
};

const parseLangFromPathname = (pathname: string) => {
  const regex = new RegExp(`(${multiLanguagePaths})$`);
  if (pathname.match(regex)) {
    return pathname.slice(-2);
  }
  return undefined;
};

export const FAQPageContainer = ({ pageData, basePath }: Props) => {
  const location = useLocation();
  const { navigateTo } = useNavigateTo();
  const lang = parseLangFromPathname(location.pathname);
  const data = getDataByLang(pageData, lang as availableLangs);
  const currentPath = removeLanguageCode(location.pathname, lang);
  const nestedPath = String(currentPath).replace(basePath, "");

  const onClickGo = (segment: string) => {
    navigateTo(buildNextUrl(currentPath, segment, lang));
  };

  return (
    <div
      className={
        "faq-page-container w-full h-full overflow-auto flex flex-col gap-[10px] pt-[12px]"
      }
    >
      <FAQPageBody
        data={data as QuestionType[]}
        faqPath={nestedPath}
        onClickGo={onClickGo}
      />
    </div>
  );
};
