import React from "react";

export const PromotionsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.607 3.27099L14.057 2.56199C14.726 2.23499 15.535 2.49699 15.884 3.15599L16.641 4.58199C16.841 4.95799 17.203 5.22099 17.623 5.29499L19.213 5.57399C19.947 5.70299 20.446 6.38999 20.342 7.12799L20.116 8.72599C20.056 9.14799 20.195 9.57399 20.491 9.87999L21.613 11.04C22.131 11.575 22.131 12.425 21.613 12.961L20.491 14.121C20.195 14.427 20.056 14.853 20.116 15.275L20.342 16.873C20.446 17.611 19.947 18.298 19.213 18.427L17.623 18.706C17.203 18.78 16.841 19.043 16.641 19.419L15.884 20.845C15.535 21.503 14.727 21.766 14.057 21.439L12.607 20.73C12.224 20.543 11.777 20.543 11.394 20.73L9.94401 21.439C9.27501 21.766 8.46601 21.504 8.11701 20.845L7.36001 19.418C7.16001 19.042 6.79801 18.779 6.37801 18.705L4.78801 18.426C4.05401 18.297 3.55501 17.61 3.65901 16.872L3.88501 15.274C3.94501 14.852 3.80601 14.426 3.51001 14.12L2.38801 12.96C1.87001 12.425 1.87001 11.575 2.38801 11.039L3.51001 9.87899C3.80601 9.57299 3.94501 9.14699 3.88501 8.72499L3.66001 7.12699C3.55501 6.38999 4.05501 5.70199 4.78901 5.57399L6.37901 5.29499C6.79801 5.22099 7.16001 4.95799 7.36001 4.58199L8.11701 3.15599C8.46601 2.49799 9.27401 2.23499 9.94401 2.56199L11.394 3.27099C11.776 3.45799 12.224 3.45799 12.607 3.27099Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15L15 9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.249 9C9.111 9 8.999 9.112 9 9.25C9 9.388 9.112 9.5 9.25 9.5C9.388 9.5 9.5 9.388 9.5 9.25C9.5 9.112 9.388 9 9.249 9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.749 14.5C14.611 14.5 14.499 14.612 14.5 14.75C14.5 14.888 14.612 15 14.75 15C14.888 15 15 14.888 15 14.75C15 14.612 14.888 14.5 14.749 14.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
