import { AxiosRequestConfig } from "axios";

import { useLogout } from "@api/queries";
import { authPaths } from "@api/services/auth/paths";
import { TokenData } from "@common/Types";
import {
  getAccessTokenLocalStorage,
  getRefreshTokenLocalStorage,
  setRefreshTokenLocalStorage,
  setTokenLocalStorage,
} from "@common/Utils";

import { apiClient } from "./apiClient";

// Extend AxiosRequestConfig to include custom properties
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _isRefreshing?: boolean;
}

// Function to generate headers for requests
const getRequestHeaders = (config: CustomAxiosRequestConfig) => {
  const accessToken = getAccessTokenLocalStorage();
  const baseHeaders = { "Content-Type": "application/json" };

  // Determine whether to include the Authorization header
  const authHeader = config._isRefreshing
    ? {}
    : { Authorization: `Bearer ${accessToken}` };

  return {
    ...config.headers,
    ...baseHeaders,
    ...authHeader,
  };
};

// Request interceptor to attach authorization header
apiClient.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    return { ...config, headers: getRequestHeaders(config) };
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor to handle token refresh
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const access_token = getAccessTokenLocalStorage();

    const isUnauthorized =
      error.response?.status === 401 && !originalRequest._retry;

    if (isUnauthorized && access_token) {
      originalRequest._retry = true;

      try {
        const JWTRequest: TokenData = await refreshTokenRequest();

        if (JWTRequest.status >= 200) {
          // Update tokens in local storage
          setRefreshTokenLocalStorage(JWTRequest.data.refresh_token);
          setTokenLocalStorage(JWTRequest.data.access_token);

          // Update headers with new access token
          apiClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${JWTRequest.data.access_token}`;

          // Retry the original request with new headers
          return apiClient(originalRequest);
        }
      } catch (refreshError) {
        useLogout();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

// Function to refresh the access token
async function refreshTokenRequest() {
  const refreshToken = getRefreshTokenLocalStorage();
  const config: CustomAxiosRequestConfig = {
    headers: { Authorization: `Bearer ${refreshToken}` },
    _isRefreshing: true,
  };

  try {
    return await apiClient.post(authPaths.refresh_token, {}, config);
  } catch (error) {
    useLogout();
    throw error;
  }
}

export { apiClient as fetcher };
