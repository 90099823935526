import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Typography } from "@components/Elements";

import { StateValues } from "../../common/types";

import { DeleteRequestComplete } from "./delete-request-complete";
import { DeleteRequestForm } from "./delete-request-form";
import { RequestAuthCodeForm } from "./request-auth-code-form";
import { RequestAuthTokenForm } from "./request-auth-token-form";

const STEPS = {
  REQUEST_AUTH_CODE: {
    component: RequestAuthCodeForm,
    next: "REQUEST_AUTH_TOKEN",
  },
  REQUEST_AUTH_TOKEN: {
    component: RequestAuthTokenForm,
    next: "REQUEST_DELETION",
  },
  REQUEST_DELETION: {
    component: DeleteRequestForm,
    next: "REQUEST_COMPLETE",
  },
  REQUEST_COMPLETE: {
    component: DeleteRequestComplete,
    next: undefined,
  },
} as const;

type State = {
  step: keyof typeof STEPS;
  values: StateValues;
};

export const DeleteAccountRequest = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    step: "REQUEST_AUTH_CODE",
    values: {},
  });

  const updateValueAndGoNext =
    (nextStep: keyof typeof STEPS | undefined) => (values: StateValues) => {
      if (nextStep) {
        setState((prevState) => {
          return {
            step: nextStep,
            values: {
              ...prevState.values,
              ...values,
            },
          };
        });
      }
    };

  const getCurrentStepForm = (step: keyof typeof STEPS) => {
    const { component: Component, next } = STEPS[step];
    if (Component) {
      return (
        <Component
          values={state.values}
          onChange={updateValueAndGoNext(next)}
        />
      );
    }

    return null;
  };

  return (
    <div className={"sm:py-32 py-16"}>
      <div className="container mx-auto px-4">
        <div className="flex items-center flex-col justify-center space-y-6 mb-[2em]">
          <Typography
            as="h4"
            variant="heading-1-a"
            className="text-[24px] md:text-[28px] mac:text-[32px] font-bold leading-[1.25] text-white"
          >
            {t("delete-account.title")}
          </Typography>
          <p className={"text-lg text-dark-300 lg:max-w-[750px] text-center"}>
            <Trans i18nKey={"delete-account.description"} t={t}>
              Вы собираетесь удалить свой аккаунт. Это действие является
              необратимым. <br />
              После удаления аккаунта вы больше не сможете пользоваться нашим
              сервисом.
            </Trans>
          </p>
        </div>
        <div className={"flex flex-col max-w-[500px] mx-auto"}>
          {getCurrentStepForm(state.step)}
        </div>
      </div>
    </div>
  );
};
