import React from "react";

import { parseMarkdown } from "@components/Shared";

type Props = {
  markdown: string;
};

export const FAQAnswer = ({ markdown }: Props) => {
  return (
    <div
      className={
        "flex flex-col px-[16px] mt-[18px] mb-[32px] markdown-content text-[14px] leading-[1.42]"
      }
    >
      {parseMarkdown(markdown)}
    </div>
  );
};
