import { ActionIcon, Input, Menu } from "@mantine/core";
import { Check, IconProps, X } from "phosphor-react";
import React, { ReactNode, useEffect, useState } from "react";

import { LocalFiltersType } from "@common/Types";

type Props<T> = {
  icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  className?: string;
  title: string;
  filters: T;
  setFilters: (value: T) => void;
  field: keyof T;
  MainIcon: ReactNode;
  options: LocalFiltersType[];
  singleChoice?: boolean;
};

export const CheckboxList = <T,>({
  // icon: Icon,
  title,
  filters,
  setFilters,
  field,
  MainIcon,
  options,
  singleChoice,
  className,
}: Props<T>) => {
  const values = filters[field];
  const [filterActive, setFilterActive] = useState(false);

  useEffect(() => {
    // @ts-ignore // TODO: remove it on refactions
    if (filters[field]?.length > 0) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
    }
  }, [filters]);

  const onReset = () => {
    if (Array.isArray(values)) {
      const obj = {
        ...filters,
        [field]: [],
      };
      setFilters(obj);
      return;
    }

    setFilters({
      ...filters,
      [field]: "",
    });
  };

  const onCheck = (option: LocalFiltersType) => {
    if (Array.isArray(values)) {
      const obj = {
        ...filters,
        [field]: values?.includes(option.value)
          ? values.filter((v: string) => v != option.value)
          : singleChoice
          ? [option.value]
          : [...values, option.value],
      };
      setFilters(obj);
      return;
    }

    setFilters({
      ...filters,
      [field]: option.value,
    });
  };

  const isCurrentSelectedValue = (value: string) => {
    if (Array.isArray(values)) {
      return values.includes(value);
    }
    // @ts-ignore
    return values === value;
  };

  return (
    <div className={className ?? "self-center"}>
      <Menu
        classNames={{
          body: `${
            field === "parks" || field === "events" ? "max-h-96" : "h-full"
          } overflow-auto`,
        }}
        closeOnScroll={false}
        size={"lg"}
        closeOnItemClick={false}
        transition={"pop-top-left"}
        shadow={"xl"}
        withArrow
        control={
          <Input
            component={"button"}
            icon={MainIcon}
            classNames={{
              input:
                "h-[40px] !text-body-2-a font-medium border-[1px] border-solid border-neutral-300 rounded-[8px]",
              icon: "pl-[10px]",
            }}
            rightSection={
              filterActive ? (
                <>
                  <ActionIcon variant="transparent">
                    <X
                      size={14}
                      color={filterActive ? "#184ddc" : "transparent"}
                      onClick={onReset}
                    />
                  </ActionIcon>{" "}
                </>
              ) : null
            }
          >
            {title}
          </Input>
        }
      >
        <Menu.Label>
          Фильтры{" "}
          <p className="font-bold cursor-pointer" onClick={onReset}>
            Сбросить
          </p>
        </Menu.Label>
        {options?.map((option) => (
          <Menu.Item
            onClick={() => onCheck(option)}
            // @ts-ignore
            color={isCurrentSelectedValue(option.value) ? "#184ddc" : "gray"}
            rightSection={
              <Check
                size={15}
                color={
                  // @ts-ignore
                  isCurrentSelectedValue(option.value)
                    ? "#184ddc"
                    : "transparent"
                }
                weight={"bold"}
              />
            }
            component={"div"}
            key={option.value}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};
