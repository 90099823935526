import React from "react";

import { isTargetNavi } from "@assets/Flags";

import { MotorHeader } from "./motor-header";
import { NaviHeader } from "./navi-header";

export const Header = () => {
  if (isTargetNavi) {
    return <NaviHeader />;
  }
  return <MotorHeader />;
};
