/**
 * Сервисы для payments
 */

import { appConfig } from "@api/config";

const src = appConfig.api.cards;

export const paymentsPath = {
  save: `${src}/save/`,
};
