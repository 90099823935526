import { fetcher } from "@common/Libs/axios";
import {
  LandingParkApplicationType,
  ParkParams,
  ParksShort,
  ParksType,
} from "@common/Types";

import { parksPath } from "./paths";

/**
 * Описание методов parks.
 */

export const parksService = {
  getParksShort: (cityId?: string | number) =>
    fetcher.get<ParksShort[]>(parksPath.parks_short, {
      params: cityId ? { cityId: cityId } : {},
    }),
  sendParkApplication: (application: LandingParkApplicationType) =>
    fetcher.post(parksPath.parks_applications, application),
  getParks: ({ limit, skip, search }: ParkParams) =>
    fetcher.get<ParksType>(parksPath.parks, {
      params: { limit, skip, search },
    }),
};
