import { Image, Modal } from "@mantine/core";
import { clsx } from "clsx";
import { isToday } from "date-fns";
import React, { FC } from "react";

import { ChatMessageType } from "@common/Types";
import { formatDate } from "@common/Utils";
import { Typography } from "@components/Elements";

type Props = {
  message: ChatMessageType;
};

export const MessageCardImage: FC<Props> = ({ message }) => {
  const [opened, setOpened] = React.useState(false);

  const dateFormat = isToday(new Date(message.created_at))
    ? "HH:mm"
    : "MM-dd-yyyy HH:mm";

  return (
    <div className="w-full flex flex-col">
      <div
        className={clsx(
          "inline-block mt-[4px] mb-[4px] rounded-t-xl max-w-sm",
          {
            "self-end": message.sender === "admin",
            "self-start": message.sender !== "admin",
          },
        )}
      >
        <div className="flex flex-col">
          <div className="flex flex-col">
            <Image
              className="flex flex-col max-w-full max-h-[100vw] cursor-pointer"
              classNames={{ image: "rounded-[20px]" }}
              src={message?.image_url || ""}
              onClick={() => setOpened(true)}
            />
          </div>
          <Modal
            size="96%"
            opened={opened}
            onClose={() => setOpened(false)}
            withinPortal
            withCloseButton
            closeOnEscape
            closeOnClickOutside
            overflow="inside"
            centered
            classNames={{
              modal: "h-full",
              body: "h-full w-full overflow-hidden",
            }}
          >
            <Image
              classNames={{
                root: "w-full h-full max-w-full max-h-full",
                figure: "w-full h-full max-w-full max-h-full",
                imageWrapper: "w-full h-full max-w-full max-h-full",
              }}
              height="100%"
              width="100%"
              src={message?.image_url || ""}
              fit="contain"
            />
          </Modal>
        </div>
        <Typography className="text-sm" color="neutral-500">
          {formatDate(message.created_at, dateFormat)}
        </Typography>
      </div>
    </div>
  );
};
