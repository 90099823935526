import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { authService } from "@api/services";
import { SendCodeTypes } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const usePostSendCode = () => {
  const { isError, error, data, ...query } = useMutation({
    mutationKey: [QUERY_KEYS.SEND_CODE],
    mutationFn: async (phone: string) => {
      return await authService.sendCode(phone);
    },
    onError: (error) => {
      showErrorToast(error as AxiosError);
    },
    onSuccess: ({ data }) => {
      toast.success(data.detail);
    },
  });

  const response = data?.data as SendCodeTypes;

  return { isError, error, response, ...query };
};
