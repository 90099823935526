import React from "react";

import { LandingLayout } from "@components/Layouts";

import { DeleteAccountRequest } from "./delete-account-request";

export const DeleteAccountPage = () => {
  return (
    <LandingLayout className="bg-custom-black">
      <DeleteAccountRequest />
    </LandingLayout>
  );
};
