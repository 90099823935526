import { TimeInputView, TimeInputViewStyles } from "./types";

const size = {
  default: "h-[40px]",
};

const font = {
  default: "text-neutral-text-800 placeholder-neutral-text-400",
};

const common = {
  focus: "focus:border-brand-border-500",
  focusError: "focus:border-negative-border-500",
  opacity: "opacity-100",
};

const classes = {
  wrapper: `${common.opacity}`,
  root: "max-w-[430px] w-full",
  inputDefault: `shadow-none border rounded-[6px] border-neutral-border-300 ${font.default} ${common.focus} ${size.default} ${common.opacity}`,
  inputNoBorder: `shadow-none border rounded-[6px] border-transparent bg-neutral-bg-50 ${font.default} ${common.focus} ${size.default} ${common.opacity}`,
  inputError: `shadow-none border rounded-[6px] border-negative-border-500 ${common.focusError} ${font.default} ${size.default} ${common.opacity}`,
  inputDisabled: `shadow-none border text-neutral-text-600 opacity-100 rounded-[6px] bg-neutral-bg-50 ${size.default} ${common.opacity}`,
  inputNoBorderDisabled: `shadow-none border rounded-[6px] border-transparent bg-neutral-bg-50 ${font.default} ${size.default} ${common.opacity}`,
  label: "text-caption-b text-neutral-text-800",
  error: "text-caption-b text-negative-text-500",
  errorDisabled: "text-caption-b text-negative-text-500",
  controls: "h-full",
};

export const VIEW_CLASSES: Record<TimeInputView, TimeInputViewStyles> = {
  default: {
    wrapper: classes.wrapper,
    root: classes.root,
    input: classes.inputDefault,
    label: classes.label,
    error: classes.error,
    controls: classes.controls,
  },
  "no-border": {
    wrapper: classes.wrapper,
    root: classes.root,
    input: classes.inputNoBorder,
    label: classes.label,
    error: classes.error,
    controls: classes.controls,
  },
  error: {
    wrapper: classes.wrapper,
    root: classes.root,
    input: classes.inputError,
    label: classes.label,
    error: classes.error,
    controls: classes.controls,
  },
  disabled: {
    wrapper: classes.wrapper,
    root: classes.root,
    input: classes.inputDisabled,
    label: classes.label,
    error: classes.errorDisabled,
    controls: classes.controls,
  },
  "no-border-disabled": {
    wrapper: classes.wrapper,
    root: classes.root,
    input: classes.inputNoBorderDisabled,
    label: classes.label,
    error: classes.errorDisabled,
    controls: classes.controls,
  },
};
