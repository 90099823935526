import { Button, Image, Paper, Text } from "@mantine/core";
import React from "react";

import { useDimension } from "@common/Hooks";

type Props = {
  imageSrc: string;
  title: string;
  text: string;
  success?: boolean;
  paymentResult: () => void;
};

export const CardComponent: React.FC<Props> = ({
  imageSrc,
  title,
  text,
  paymentResult,
  success,
}) => {
  const { heightD } = useDimension();

  return (
    <Paper
      className="w-full h-screen mx-auto text-center overflow-hidden"
      shadow="lg"
      p="md"
      radius="lg"
    >
      <div className="mt-8 h-5/6">
        <div
          style={{ width: success && heightD > 700 ? 230 : 130 }}
          className="mx-auto my-10"
        >
          <Image
            className={`${heightD > 700 ? "h-96" : "h-60 "}`}
            src={imageSrc}
          />
        </div>

        <Text
          weight="bold"
          align="center"
          className="my-3 text-xl md:text-2xl uppercase"
        >
          {title}
        </Text>
        <Text
          align="center"
          className="text-gray-400 rounded text-lg md:text-xl whitespace-pre-wrap"
        >
          {text}
        </Text>
      </div>

      <Button
        onClick={paymentResult}
        className="w-full max-w-2xl font-bold py-2 mx-auto rounded-md block"
      >
        Хорошо
      </Button>
    </Paper>
  );
};
