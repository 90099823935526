import { NavigateOptions, To, useNavigate } from "react-router-dom";

interface NavigateOptionsExt extends NavigateOptions {
  _blank?: boolean;
}

export const useNavigateTo = () => {
  const navigate = useNavigate();

  const navigateTo = (to: To, options?: NavigateOptionsExt) => {
    if (options?._blank) {
      const url =
        typeof to === "string" ? to : `${to.pathname ?? ""}${to.search ?? ""}`;
      return window.open(url, "_blank", "noopener,noreferrer");
    }

    return navigate(to, options);
  };

  const navigateBack = () => {
    return navigate(-1);
  };

  const navigateForward = () => {
    return navigate(1);
  };

  return { navigateTo, navigateBack, navigateForward };
};
