import React from "react";

export const RidesIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5859 20.5859L3.41387 13.4139C2.63287 12.6329 2.63287 11.3669 3.41387 10.5859L10.5859 3.41387C11.3669 2.63287 12.6329 2.63287 13.4139 3.41387L20.5859 10.5859C21.3669 11.3669 21.3669 12.6329 20.5859 13.4139L13.4139 20.5859C12.6329 21.3669 11.3669 21.3669 10.5859 20.5859Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 14.203V12.068C9.5 11.478 9.975 11 10.561 11H15"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 9.5L15 11L13.5 12.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
