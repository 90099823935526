import { ROLES_MOTOR, ROLES_NAVI } from "@common/Constants";

export type LocalStorageReturnType = string | null;

export const getUserRole = (): LocalStorageReturnType => {
  return localStorage.getItem("userRole");
};

export const setUserRole = (role: ROLES_MOTOR | ROLES_NAVI): void => {
  localStorage.setItem("userRole", role);
};

export const removeUserRole = (): void => {
  return localStorage.removeItem("userRole");
};
