import { ButtonSizeStyles, ButtonView } from "./types";

export const buttonSizeClasses: ButtonSizeStyles = {
  sm: "py-[6px] px-[12px] text-caption-b font-medium gap-1 rounded-lg",
  md: "py-[10px] px-[16px] text-body-2-b font-medium gap-1 rounded-lg",
  lg: "py-[12px] px-[20px] text-body-1-b font-semibold gap-2 rounded-xl",
  xl: "py-[16px] px-[22px] text-body-1-b font-semibold gap-2 rounded-2xl",
};

export const VIEW_CLASSES: Record<ButtonView, string> = {
  brand:
    "bg-brand-bg-200 flex items-center transition-colors border-none text-brand-text-1000",

  inner:
    "bg-transparent border-[1px] border-solid border-neutral-border-300 flex items-center transition-colors text-neutral-text-900",

  default:
    "bg-brand-bg-200 flex items-center transition-colors border-none text-brand-text-1000",

  green:
    "bg-brand-bg-1000 flex items-center transition-colors border-none text-brand-text-0",

  gray: "bg-neutral-bg-100 flex items-center transition-colors border-none text-neutral-text-900",

  transparent:
    "bg-transparent flex items-center transition-colors border-none hover:bg-gray-100 text-brand-text-1000",

  none: "bg-transparent p-0 border-none",
};
