import { Group, SegmentedControl, Title } from "@mantine/core";
import React from "react";

import { FRAUD_CONTROL_FILTERS } from "@common/Constants";
import { FraudControlStatus } from "@common/Types";

type Props = {
  filterValue: FraudControlStatus;
  setFilterValue: (value: FraudControlStatus) => void;
};

export const FraudControlTypeFilters = ({
  filterValue,
  setFilterValue,
}: Props) => {
  return (
    <Group spacing={"xs"} direction="column">
      <Title order={5}>Аудитория</Title>
      <div className={`inline-flex items-center`}>
        <SegmentedControl
          size={"sm"}
          className={"z-0"}
          value={filterValue}
          onChange={setFilterValue}
          data={FRAUD_CONTROL_FILTERS}
        />
      </div>
    </Group>
  );
};
