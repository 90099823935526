export const QUERY_KEYS = {
  ACCESSES: "ACCESSES",
  USER: "USER",
  LANDING_TARIFFS: "LANDING_TARIFFS",
  LANDING_NEWS: "LANDING_NEWS",
  LANDING_CITIES: "LANDING_CITIES",
  DRIVERS_LOCATION: "DRIVERS_LOCATION",
  DRIVERS: "DRIVERS",
  PARKS_SHORT: "PARKS_SHORT",
  PARKS: "PARKS",
  LANDING_PARK_APPLICATION: "LANDING_PARK_APPLICATION",
  LANDING_DRIVER_APPLICATION: "LANDING_DRIVER_APPLICATION",
  PAYMENTS_SAVE: "PAYMENTS_SAVE",

  // antifraudsd
  ANTI_FRAUDS: "ANTI_FRAUDS",
  ANTI_FRAUDS_REASON: "ANTI_FRAUDS_REASON",
  ANTI_FRAUDS_SETTINGS: "ANTI_FRAUDS_SETTINGS",

  PROMOTIONS: "PROMOTIONS",
  PROMOTION_DETAILS: "PROMOTION_DETAILS",
  PROMOTION_REPORT: "PROMOTION_REPORT",
  RIDE_PROMOTION_REPORTS: "RIDE_PROMOTION_REPORTS",
  PROMOTION_PARTICIPANTS: "PROMOTION_PARTICIPANTS",
  RIDERS_REFERRAL_PROMOTION_PARTICIPANTS:
    "RIDERS_REFERRAL_PROMOTION_PARTICIPANTS",
  DRIVERS_REFERRAL_PROMOTION_PARTICIPANTS:
    "DRIVERS_REFERRAL_PROMOTION_PARTICIPANTS",
  REFERRAL_PROMOTION_REPORTS: "REFERRAL_PROMOTION_REPORTS",
  RIDE_INDIVIDUAL_PROMOTION_PARTICIPANTS:
    "RIDE_INDIVIDUAL_PROMOTION_PARTICIPANTS",
  PROMOTION_PARKS_PARTICIPANTS: "PROMOTION_PARKS_PARTICIPANTS",
  CITIES: "CITIES",
  SEND_CODE: "SEND_CODE",
  VERIFY_CODE: "VERIFY_CODE",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  TARIFFS: "TARIFFS",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST: "SUPPORT_RIDERS_CHAT_MESSAGE_LIST",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST_EXACT:
    "SUPPORT_RIDERS_CHAT_MESSAGE_LIST_EXACT",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST_BY_ID:
    "SUPPORT_RIDERS_CHAT_MESSAGE_LIST_BY_ID",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST: "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_EXACT:
    "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_EXACT",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_BY_ID:
    "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_BY_ID",
};
