import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { promotionsService } from "@api/services";
import { showErrorToast } from "@common/Utils";

export const useDeletePromotions = () => {
  const { isError, error, data, ...query } = useMutation({
    mutationFn: (promotionId: string) =>
      promotionsService.deletePromotion(promotionId),
    onError: (error) => {
      showErrorToast(error as AxiosError);
    },
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
