import { ROLES_MOTOR, ROLES_NAVI } from "@common/Constants";
import { RoleType } from "@common/Types";

const ROLES = { ...ROLES_MOTOR, ...ROLES_NAVI };

export const roleName = (role: RoleType) => {
  switch (role) {
    case ROLES.RIDER:
      return "Клиент";
    case ROLES.DRIVER:
      return "Водитель";
    case ROLES.MOTORADMIN:
      return "Администратор мотор такси";
    case ROLES.NAVIADMIN:
      return "Администратор нави такси";
    case ROLES.PARKADMIN:
      return "Администратор таксопарка";
    case ROLES.CITYSUPERADMIN:
      return "Администратор города";
    case ROLES.SUPERADMIN:
      return "Суперадминистратор";
    default:
      return role;
  }
};
