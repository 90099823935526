import { TimeInput as MantineTimeInput } from "@mantine/dates";
import React from "react";

import { Props, TimeInputView } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * TimeInput component renders a time input field with customizable labels, description,
 * icon, and error handling. It utilizes the Mantine `TimeInput` component as its base.
 *
 * @param {Props} props - The properties for the TimeInput component.
 * @returns {JSX.Element} The rendered TimeInput component.
 */

export const TimeInput: React.FC<Props> = ({
  view = "default",
  defaultValue,
  value,
  error,
  disabled,
  ...props
}) => {
  let currentView = view as TimeInputView;
  const getValue = value ?? defaultValue;

  if (disabled) {
    currentView = view === "no-border" ? "no-border-disabled" : "disabled";
  } else if (error) {
    currentView = "error";
  }

  return (
    <MantineTimeInput
      hoursLabel="hours"
      minutesLabel="minutes"
      value={getValue}
      error={error}
      disabled={disabled}
      classNames={VIEW_CLASSES[currentView]}
      {...props}
    />
  );
};
