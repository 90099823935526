/**
 * Сервисы для tariffs
 */

import { appConfig } from "@api/config";

const src = appConfig.api.tariffs;

export const tariffsPath = {
  tariffs: `${src}/`,
};
