import { useContext } from "react";

import { SocketContext } from "@common/Providers";

/**
 * Custom hook to access the WebSocket context.
 *
 * This hook provides access to the WebSocket context created by
 * the `SocketProvider`. It allows you to access the WebSocket
 * status (`online` state) and the `observer` object used for
 * managing WebSocket events.
 *
 * @returns {Object} The context value, which includes:
 * - `observer`: An object for managing WebSocket events.
 * - `online`: A boolean indicating whether the WebSocket connection is active.
 *
 * @example
 * const { online, observer } = useSocket();
 * if (online) {
 *   // WebSocket is connected
 * }
 * observer.on("event", () => {
 *   // Handle WebSocket event
 * });
 */
export const useSocket = () => useContext(SocketContext);
