import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";

import { useGetUserRole, useLogin } from "@api/queries";
import { LogotypeIcon } from "@assets/Icons";
import { ROLES_MOTOR, ROLES_NAVI, ROUTES } from "@common/Constants";
import { useNavigateTo } from "@common/Hooks";
import { UserCredentials } from "@common/Types";
import { getAccessTokenLocalStorage, getUserRole } from "@common/Utils";

type RenderProps = {
  handlePhoneFocus: () => void;
  handleSubmit: (values: UserCredentials) => void;
  isLoading: boolean;
  phoneRef: React.RefObject<HTMLInputElement>;
};

type Props = {
  children: (props: RenderProps) => React.ReactNode;
};

export const LoginLayout = ({ children }: Props) => {
  const phoneRef = useRef<HTMLInputElement>(null);

  const { navigateTo } = useNavigateTo();

  const userRole = getUserRole();
  const hasAccessToken = getAccessTokenLocalStorage();

  const {
    mutateAsync,
    data: loginResponse,
    isSuccess: isSuccessLogin,
    isPending: isPendingLogin,
  } = useLogin();

  const {
    refetch,
    isSuccess: isSuccessGetUserRole,
    isLoading: isLoadingUserRole,
  } = useGetUserRole(loginResponse?.data.access_token || "");

  useEffect(() => {
    handleUserRole();
  }, []);

  useEffect(() => {
    if (isSuccessLogin) {
      refetch();
    }
  }, [isSuccessLogin]);

  useEffect(() => {
    if (isSuccessGetUserRole) {
      handleGetUser();
    }
  }, [isSuccessGetUserRole]);

  const handleSubmit = async (values: UserCredentials) => {
    await mutateAsync(values);
  };

  const handleGetUser = () => {
    if (isSuccessGetUserRole) {
      toast.success("Вход успешно выполнен");
    }

    return handleUserRole();
  };

  const handlePhoneFocus = () => {
    if (phoneRef.current !== null) {
      phoneRef?.current.focus();
    }
  };

  const handleUserRole = () => {
    if (!hasAccessToken) return;

    const isParkAdmin =
      userRole === ROLES_NAVI.PARKADMIN || ROLES_MOTOR.MOTORADMIN;

    if (isParkAdmin) {
      return navigateTo(ROUTES.DRIVERS);
    }

    return navigateTo(ROUTES.DASHBOARD);
  };

  const isLoading = isLoadingUserRole || isPendingLogin;

  return (
    <section className="bg-blue-50 flex justify-center items-center h-screen">
      <div className="flex items-center flex-col w-full">
        <LogotypeIcon />
        <div className="bg-white md:px-20 px-8 py-16 mt-5 rounded-lg max-w-3xl border border-gray-300">
          <div className="flex items-center justify-center flex-col">
            <h2 className="font-medium text-3xl text-gray-600">
              Вход в админку
            </h2>
            <p className="font-light text-base text-gray-400 mt-5">
              Введите данные вашей учетной записи для входа в систему.
            </p>
          </div>
          <div className="mt-12">
            {children({ isLoading, handlePhoneFocus, handleSubmit, phoneRef })}
          </div>
        </div>
      </div>
    </section>
  );
};
