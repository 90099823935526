import { appConfig } from "@api/config";

/**
 * Сервисы для chats
 */
const src = appConfig.api.dashboard;

export const chatPath = {
  // driver
  support_driver_chat: `${src}/drivers/support/chats/`,
  support_driver_chat_messages: (id: string) =>
    `${src}/drivers/support/${id}/messages/`,

  // rider
  support_rider_chat: `${src}/riders/support/chats/`,
  support_rider_chat_messages: (id: string) =>
    `${src}/riders/support/${id}/messages/`,
};
