import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteAccount } from "@api/queries";
import { LoadingIcon } from "@assets/Icons";
import { ROUTES } from "@common/Constants";
import { useNavigateTo } from "@common/Hooks";
import { ContactFormPhoneField } from "@components/Elements";

import { StateValues } from "../../common/types";
import { RequestAuthCodeFormValidationSchema } from "../../common/utils";

export type RequestAuthCodeForm = {
  phone: string;
};

const initialValues: RequestAuthCodeForm = {
  phone: "",
};

type Props = {
  values: StateValues;
  onChange: (values: StateValues) => void;
};

export const DeleteRequestForm = ({ values, onChange }: Props) => {
  const { navigateTo } = useNavigateTo();

  const { t, i18n } = useTranslation();

  const { mutateAsync: deleteAccountRequest, isPending: loading } =
    useDeleteAccount();

  const validationSchema = useMemo(() => {
    return RequestAuthCodeFormValidationSchema(t);
  }, [i18n.language]);

  const onSubmit = async () => {
    if (!values.access_token) return;
    await deleteAccountRequest(values.access_token as string);
    onChange({});
  };

  const onClickCancel = () => {
    navigateTo(ROUTES.MAIN);
  };

  return (
    <div className={"flex flex-col gap-[2em]"}>
      <div className={"flex flex-col text-white gap-[1em] text-[16px]"}>
        <div className={"flex flex-col text-[18px] font-bold"}>
          <span>{t("delete-account.delete-request-warning-text-title")}</span>
        </div>
        <div className={"flex flex-col"}>
          <ol className={"flex flex-col list-disc gap-[0.5em]"}>
            <li>{t("delete-account.delete-request-common-text-point")}</li>
            {values.roles?.includes("driver") && (
              <li>{t("delete-account.delete-request-driver-text-point")}</li>
            )}
            {values.roles?.includes("rider") && (
              <li>{t("delete-account.delete-request-rider-text-point")}</li>
            )}
          </ol>
        </div>
      </div>
      <div className={"flex w-full items-center justify-center"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values: formValues }) => (
            <Form className={"w-full space-y-6"}>
              <ContactFormPhoneField
                name={"phone"}
                label={t("delete-account.retry-phone-field")}
                placeholder={t("delete-account.retry-phone-field-placeholder")}
                error={errors.phone}
                touched={touched.phone}
              />
              <div
                className={"flex w-full items-center justify-center gap-[2em]"}
              >
                <button
                  type={"button"}
                  onClick={onClickCancel}
                  className="inline-flex items-center justify-center h-10 px-6 space-x-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-600 hover:active:bg-blut-700 disabled:opacity-50 focus:shadow-outline focus:outline-none"
                >
                  <span>{t("delete-account.cancel-button")}</span>
                </button>
                <button
                  type={"submit"}
                  disabled={formValues.phone !== values.phone || loading}
                  className="inline-flex items-center justify-center h-10 px-6 space-x-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-red-600 hover:active:bg-red-700 disabled:opacity-50 focus:shadow-outline focus:outline-none"
                >
                  {loading && <LoadingIcon />}
                  <span>{t("delete-account.delete-button")}</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
