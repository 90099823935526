const baseUrl = process.env.REACT_APP_API_URL;

type HTTPMethod =
  | "GET"
  | "POST"
  | "PUT"
  | "DELETE"
  | "PATCH"
  | "OPTIONS"
  | "HEAD";

type Props = {
  path: string;
  token: string;
  method: HTTPMethod;
};

export const NativeFetcher = <T>({
  path,
  token,
  method,
}: Props): Promise<T> => {
  return fetch(`${baseUrl}${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => response.json());
};
