import React from "react";

import HeaderDropdown from "./components/shared/header-dropdown";

type Props = {
  handleSidebar: () => void;
};

export const DashboardHeader: React.FC<Props> = ({ handleSidebar }) => {
  return (
    <div className="w-full sticky flex-shrink-0 top-0 bg-white border-b border-gray-300 z-10">
      {!navigator.onLine && (
        <div className="bg-red-600 text-white py-1 flex items-center justify-center font-medium">
          Отсутствует соединение
        </div>
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          <div className="flex">
            <button
              className="text-gray-500 hover:text-gray-600 lg:hidden"
              onClick={handleSidebar}
            >
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>
          <div className="flex items-center">
            <HeaderDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};
