import { apiClient } from "@common/Libs/axios";
import { LandingTariffType } from "@common/Types";

import { landingTariffsPath } from "./paths";

/**
 * Описание методов tariffs.
 */

export const landingTariffsService = {
  getTariffs: () =>
    apiClient.get<LandingTariffType[]>(landingTariffsPath.tariffs),
};
