import { MantineProvider } from "@mantine/core";
import React from "react";
import { Toaster } from "react-hot-toast";

import { FeatureFlagsProvider } from "@common/Providers";

type Props = {
  children: React.ReactNode;
};

// TODO: uncomment QueryClientProvider
export const AppProvidersLayout: React.FC<Props> = ({ children }) => {
  return (
    <FeatureFlagsProvider>
      <MantineProvider
        emotionOptions={{
          key: "mantine",
          prepend: false,
        }}
        theme={{
          fontFamily: "Inter",
          primaryColor: "indigo",
        }}
      >
        {children}
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          toastOptions={{
            duration: 10000,
            style: {
              maxWidth: "400px",
            },
          }}
        />
      </MantineProvider>
    </FeatureFlagsProvider>
  );
};
