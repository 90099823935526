import enTranslation from "./en/translation.json";
import enValidation from "./en/validation.json";
import kkTranslation from "./kk/translation.json";
import kkValidation from "./kk/validation.json";
import ruTranslation from "./ru/translation.json";
import ruValidation from "./ru/validation.json";

export const motorLocales = {
  en: {
    translation: enTranslation,
    validation: enValidation,
  },
  ru: {
    translation: ruTranslation,
    validation: ruValidation,
  },
  kk: {
    translation: kkTranslation,
    validation: kkValidation,
  },
};
