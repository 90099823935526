import React, { lazy } from "react";

const AppLoader = lazy(() => {
  const isMotor = process.env.REACT_APP_BUILD_TARGET === "motor";
  return isMotor ? import("./motor") : import("./navi");
});

export const AppRouter: React.FC = () => {
  return <AppLoader />;
};
