/**
 * Сервисы для users
 */

import { appConfig } from "@api/config";

const src = appConfig.api.users;

export const usersPath = {
  verify_code: `${src}/verify-code/`,
  delete_account: `${src}/delete-account/`,
};
