import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionReportType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useGetPromotionReports = (promotionId: string) => {
  const { isError, error, data, ...query } = useQuery<
    AxiosResponse<PromotionReportType>
  >({
    queryKey: [QUERY_KEYS.PROMOTION_REPORT, promotionId],
    queryFn: async () =>
      await promotionsService.getPromotionReport(promotionId),
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
