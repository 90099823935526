/* eslint-disable */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { motorLocales, naviLocales } from "@assets/Locales";
import { LanguageType } from "@common/Types";

const isDevelopment = process.env.NODE_ENV !== "production";
const isNavi = process.env.REACT_APP_BUILD_TARGET === "navi";

export const motorSupportedLanguages = {
  kk: {
    label: "KZ",
    fullName: "Қазақша",
  },
  ru: {
    label: "РУС",
    fullName: "Русский",
  },
  en: {
    label: "ENG",
    fullName: "English",
  },
};

export const naviSupportedLanguages = {
  ru: {
    label: "РУС",
    fullName: "Русский",
  },
  ky: {
    label: "KG",
    fullName: "Кыргызский",
  },
  en: {
    label: "ENG",
    fullName: "English",
  },
};

export const supportedLanguages = isNavi
  ? naviSupportedLanguages
  : motorSupportedLanguages;

export const AVAILABLE_LANGUAGE_FILTER: LanguageType[] = Object.entries(
  supportedLanguages,
).map(([key, value]) => {
  return {
    fullName: value.fullName,
    label: value.label,
    value: key,
  };
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ru",
    supportedLngs: Object.keys(supportedLanguages),
    load: "languageOnly",
    debug: isDevelopment,
    saveMissing: isDevelopment,

    interpolation: {
      escapeValue: false,
    },
    resources: isNavi ? naviLocales : motorLocales,
  });

export default i18n;
