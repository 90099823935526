import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { authService } from "@api/services";
import { ROLES_NAVI } from "@common/Constants";
import { MeResponse } from "@common/Types";
import { setUserRole, showErrorToast } from "@common/Utils";

export const useGetUserRole = (token: string) => {
  // GET user {id: userId, role: "userRole"}, need to set config headers with access_token from previous request.
  const { data, isSuccess, isError, error, ...query } = useQuery<
    AxiosResponse<MeResponse>
  >({
    queryKey: [QUERY_KEYS.USER, token],
    queryFn: async () => await authService.getRole(token),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  if (isSuccess) {
    setUserRole(data?.data.role as ROLES_NAVI);
  }

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  return { ...query, isSuccess, data };
};
