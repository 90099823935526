import { TFunction } from "i18next";
import * as Yup from "yup";

import { isTargetNavi } from "@assets/Flags";

const getValidationSchemaNavi = (t: TFunction) => {
  return Yup.object().shape({
    phone: Yup.string()
      .required(t("validation:required-field"))
      .matches(/\+996 \(\d{3}\) \d{2}-\d{2}-\d{2}/, {
        message: t("validation:invalid-phone"),
      }),
  });
};

const getValidationSchemaMotor = (t: TFunction) => {
  return Yup.object().shape({
    phone: Yup.string()
      .required(t("validation:required-field"))
      .matches(/\+7 \(\d{3}\) \d{3} \d{4}/, {
        message: t("validation:invalid-phone"),
      }),
  });
};

export const RequestAuthCodeFormValidationSchema = (t: TFunction) => {
  return isTargetNavi
    ? getValidationSchemaNavi(t)
    : getValidationSchemaMotor(t);
};
