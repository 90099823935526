import { Button, Paper, Text } from "@mantine/core";
import React, { useEffect } from "react";

import Cup from "./assets/images/cup.gif";

export const CardLinkSuccess: React.FC = () => {
  const paymentResultOk = () => {
    console.log("Success");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      paymentResultOk();
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Paper className="w-full h-screen overflow-hidden" shadow="lg">
      <div className="mt-8 h-full flex flex-col justify-center">
        <div
          className="mx-auto w-[154px] h-[264px] bg-contain bg-no-repeat my-[32px]"
          style={{ backgroundImage: `url(${Cup})` }}
        />

        <Text
          weight="bold"
          align="center"
          className="text-xl md:text-2xl mb-[8px]"
        >
          Пожалуйста, ожидайте
        </Text>
        <Text
          align="center"
          className="rounded text-lg md:text-xl whitespace-pre-wrap"
          style={{ color: "#7D7E80" }}
        >
          {`Банковская карта успешно принята \n в обработку`}
        </Text>

        <Button
          onClick={paymentResultOk}
          className="w-[328px] font-bold mx-auto block h-[52px] mb-[100px] mt-auto"
          style={{ backgroundColor: "#3065ED", borderRadius: 16 }}
        >
          Хорошо
        </Button>
      </div>
    </Paper>
  );
};
