import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { AniFraudsQueryParams, FraudControlType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

type Params = AniFraudsQueryParams & {
  enabled?: boolean;
};
export const useGetAntiFrauds = ({ enabled = true, ...params }: Params) => {
  const { isError, error, data, ...query } = useQuery<
    AxiosResponse<FraudControlType>
  >({
    queryKey: [QUERY_KEYS.ANTI_FRAUDS, params],
    queryFn: async () => await antiFraudsService.getAntiFraud(params),
    enabled,
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
