import React from "react";

export const TransactionIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9999 10.5H15.0599C13.5849 10.5 12.3889 9.30401 12.3889 7.82901V7.82901C12.3889 7.12101 12.6699 6.44101 13.1709 5.94001L15.4889 3.62201"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.55797 16.58L6.23597 14.063C6.53997 13.607 7.05097 13.333 7.59897 13.333V13.333C8.21997 13.333 8.78597 12.983 9.06397 12.427L9.18997 12.176C9.41997 11.715 9.41997 11.172 9.18997 10.711L8.11997 8.57199C7.84197 8.01699 7.27497 7.66699 6.65397 7.66699H2.49097"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10.5C19 5.795 15.205 2 10.5 2C5.795 2 2 5.795 2 10.5C2 15.205 5.795 19 10.5 19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16.2V19.4C14 20.284 14.716 21 15.6 21H20.4C21.284 21 22 20.284 22 19.4V16.2C22 15.316 21.284 14.6 20.4 14.6H15.6C14.716 14.6 14 15.316 14 16.2Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 17.5H14"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
