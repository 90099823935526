import React from "react";
import { Outlet } from "react-router-dom";

import { Col, Flexbox } from "@components/Elements";
import { DashboardHeader, DashboardSidebar } from "@components/Shared";

import { DashboardProvidersLayout } from "./dashboard-providers-layout";

type Props = {
  children?: React.ReactNode;
};

export const DashboardLayout = ({ children }: Props) => {
  const [isOpenSidebar, setOpenSideBar] = React.useState(false);

  const handleSidebar = () => {
    setOpenSideBar(!isOpenSidebar);
  };

  return (
    <DashboardProvidersLayout>
      <Flexbox
        flexDirection="flex-row"
        alignItems="items-stretch"
        className="flex h-screen overflow-hidden"
      >
        <DashboardSidebar isOpen={isOpenSidebar} setOpen={handleSidebar} />

        <Col className="flex-1 overflow-x-hidden">
          <DashboardHeader handleSidebar={handleSidebar} />
          <Flexbox
            as="main"
            flexDirection="flex-col"
            alignItems="items-stretch"
            className="flex-auto md:p-4 p-2 w-full py-[24px] pl-[24px] pr-[24px]"
          >
            {children ? children : <Outlet />}
          </Flexbox>
        </Col>
      </Flexbox>
    </DashboardProvidersLayout>
  );
};
