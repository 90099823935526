import { useContext } from "react";

import { ChatContext } from "@common/Providers";

/**
 * Custom hook to access the chat context.
 *
 * This hook provides access to the `ChatContext`, which includes
 * the current selected chat and a function to update it.
 *
 * @returns {ChatContextType} The context value, which includes:
 * - `selectedChat`: The currently selected chat or `null` if no chat is selected.
 * - `setSelectedChat`: A function to set the selected chat.
 *
 * @example
 * const { selectedChat, setSelectedChat } = useSelectedChat();
 * if (selectedChat) {
 *   // Use selectedChat
 * }
 * setSelectedChat(newChat);
 */
export const useSelectedChat = () => useContext(ChatContext);
