import { appConfig } from "@api/config";

/**
 * Сервисы для news
 */

const src = appConfig.api.landing;

export const landingNewsPath = {
  news: `${src}/news/`,
};
