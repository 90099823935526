import {
  PromotionAudienceType,
  PromotionTermsType,
  PromotionTypesType,
} from "@common/Types";

export const PROMOTION_TERMS_LABEL: Record<PromotionTermsType, string> = {
  first_payment_card_save: "Первая привязка карты",
  registration: "Первая регистрация",
  referral_promocode: "Активация промокода",
  cashback: "Кэшбек за поездки",
  ride: "Достижение цели по поездкам ",
};

export const PROMOTION_AUDIENCE_LABEL: Record<PromotionAudienceType, string> = {
  driver: "Водители",
  rider: "Пассажиры",
  all: " Все",
};

export const PROMOTION_TYPES_LABEL: Record<PromotionTypesType, string> = {
  bonuses: "Бонусы",
  balance: "Баланс",
};
