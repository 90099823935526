import React, { createContext, ReactNode, useContext, useState } from "react";

interface FeatureFlags {
  [key: string]: boolean;
}

interface FeatureFlagsContextProps {
  flags: FeatureFlags;
  setFlag: (key: string, value: boolean) => void;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextProps | undefined>(
  undefined,
);

const initialFlags = {
  isAuthorized: false,
};

export const FeatureFlagsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [flags, setFlags] = useState<FeatureFlags>(initialFlags);

  const setFlag = (key: string, value: boolean) => {
    if (key in initialFlags) {
      setFlags((prevFlags) => ({ ...prevFlags, [key]: value }));
    } else {
      console.warn(`Flag "${key}" does not exist.`);
    }
  };

  return (
    <FeatureFlagsContext.Provider value={{ flags, setFlag }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = (): FeatureFlagsContextProps => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagsProvider",
    );
  }
  return context;
};
