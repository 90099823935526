import { Modal as MantineModal, ModalProps } from "@mantine/core";
import React from "react";

import { ModalView } from "./types";
import { VIEW_CLASSES } from "./variants";

type Props = ModalProps & {
  title?: string;
  view?: ModalView;
  children: React.ReactNode;
};

export const Modal: React.FC<Props> = ({
  opened,
  onClose,
  title,
  children,
  view = "white",
  ...props
}) => {
  return (
    <MantineModal
      opened={opened}
      onClose={onClose}
      title={title}
      classNames={VIEW_CLASSES[view]}
      {...props}
    >
      {children}
    </MantineModal>
  );
};
