import { Button as MantineButton } from "@mantine/core";
import React from "react";

import { cn } from "@common/Utils";

import { buttonSizeClasses, VIEW_CLASSES } from "../variants";

import { Props } from "./types";

/**
 * A customizable button component.
 *
 * This component uses Mantine's Button component as a base and applies custom styles based on the provided `view` prop.
 * It also allows an optional icon to be placed on the left side of the button.
 *
 * @param {Props} props - The props for the Button component.
 * @returns {React.FC} - The Button component.
 */

export const Button: React.FC<Props> = ({
  onClick,
  children,
  icon,
  size = "sm",
  view = "default",
  className,
  ...props
}) => {
  return (
    <MantineButton
      onClick={onClick}
      leftIcon={icon}
      className={cn(
        "h-auto justify-center",
        VIEW_CLASSES[view],
        view !== "none" ? buttonSizeClasses[size] : "",
        className,
      )}
      classNames={{
        icon: "mr-0",
        inner: "[gap:inherit]",
      }}
      {...props}
    >
      {children}
    </MantineButton>
  );
};
