import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionFiltersType, PromotionType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useGetPromotions = (filters?: PromotionFiltersType) => {
  const { isError, error, data, refetch, ...query } = useQuery<
    AxiosResponse<PromotionType>
  >({
    queryKey: [QUERY_KEYS.PROMOTIONS, filters],
    queryFn: async () => await promotionsService.getPromotions(filters),
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, refetch, ...query };
};
