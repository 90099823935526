import React from "react";

import { isTargetNavi } from "@assets/Flags";

import { MotorFooter } from "./motor-footer";
import { NaviFooter } from "./navi-footer";

export const Footer = () => {
  if (isTargetNavi) {
    return <NaviFooter />;
  }

  return <MotorFooter />;
};
