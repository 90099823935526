import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { permissionsService } from "@api/services";
import { AccessPages } from "@common/Types";
import { showErrorToast } from "@common/Utils";

/**
 * Получение списка accesses
 */

export const useGetPermissions = (enabled = true) => {
  const {
    isError,
    error,
    data: response,
    ...other
  } = useQuery<AxiosResponse<AccessPages[]>>({
    queryKey: [QUERY_KEYS.ACCESSES],
    queryFn: () => permissionsService.getNaviPermissions(),
    enabled,
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  return { isError, error, response: response?.data, ...other };
};
