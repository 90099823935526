import { useEffect, useState } from "react";

import { DESKTOP_START_WIDTH } from "@common/Constants";

/**
 * A custom hook to track the window dimensions and whether the viewport is considered desktop.
 *
 * @returns {Object} An object containing the current width, height, and whether the viewport is desktop.
 */
export const useDimension = () => {
  const [dimensions, setDimensions] = useState({
    widthD: window.innerWidth,
    heightD: window.innerHeight,
  });

  const isDesktop = dimensions.widthD >= DESKTOP_START_WIDTH;

  const updateDimensions = () => {
    setDimensions({
      widthD: window.innerWidth,
      heightD: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return { ...dimensions, isDesktop };
};
