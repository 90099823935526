import { ColorClassKey } from "@common/Types";

export const backgroundColorClasses: { [key in ColorClassKey]: string } = {
  // Neutral
  "neutral-0": "bg-neutral-bg-0",
  "neutral-50": "bg-neutral-bg-50",
  "neutral-75": "bg-neutral-bg-75",
  "neutral-100": "bg-neutral-bg-100",
  "neutral-200": "bg-neutral-bg-200",
  "neutral-300": "bg-neutral-bg-300",
  "neutral-400": "bg-neutral-bg-400",
  "neutral-500": "bg-neutral-bg-500",
  "neutral-600": "bg-neutral-bg-600",
  "neutral-700": "bg-neutral-bg-700",
  "neutral-800": "bg-neutral-bg-800",
  "neutral-900": "bg-neutral-bg-900",
  "neutral-1000": "bg-neutral-bg-1000",

  // Brand
  "brand-0": "bg-brand-bg-0",
  "brand-50": "bg-brand-bg-50",
  "brand-75": "bg-brand-bg-75",
  "brand-100": "bg-brand-bg-100",
  "brand-200": "bg-brand-bg-200",
  "brand-300": "bg-brand-bg-300",
  "brand-400": "bg-brand-bg-400",
  "brand-500": "bg-brand-bg-500",
  "brand-600": "bg-brand-bg-600",
  "brand-700": "bg-brand-bg-700",
  "brand-800": "bg-brand-bg-800",
  "brand-900": "bg-brand-bg-900",
  "brand-1000": "bg-brand-bg-1000",

  // Attentive
  "attentive-0": "bg-attentive-bg-0",
  "attentive-50": "bg-attentive-bg-50",
  "attentive-75": "bg-attentive-bg-75",
  "attentive-100": "bg-attentive-bg-100",
  "attentive-200": "bg-attentive-bg-200",
  "attentive-300": "bg-attentive-bg-300",
  "attentive-400": "bg-attentive-bg-400",
  "attentive-500": "bg-attentive-bg-500",
  "attentive-600": "bg-attentive-bg-600",
  "attentive-700": "bg-attentive-bg-700",
  "attentive-800": "bg-attentive-bg-800",
  "attentive-900": "bg-attentive-bg-900",
  "attentive-1000": "bg-attentive-bg-1000",

  // Positive
  "positive-0": "bg-positive-bg-0",
  "positive-50": "bg-positive-bg-50",
  "positive-75": "bg-positive-bg-75",
  "positive-100": "bg-positive-bg-100",
  "positive-200": "bg-positive-bg-200",
  "positive-300": "bg-positive-bg-300",
  "positive-400": "bg-positive-bg-400",
  "positive-500": "bg-positive-bg-500",
  "positive-600": "bg-positive-bg-600",
  "positive-700": "bg-positive-bg-700",
  "positive-800": "bg-positive-bg-800",
  "positive-900": "bg-positive-bg-900",
  "positive-1000": "bg-positive-bg-1000",

  // Negative
  "negative-0": "bg-negative-bg-0",
  "negative-50": "bg-negative-bg-50",
  "negative-75": "bg-negative-bg-75",
  "negative-100": "bg-negative-bg-100",
  "negative-200": "bg-negative-bg-200",
  "negative-300": "bg-negative-bg-300",
  "negative-400": "bg-negative-bg-400",
  "negative-500": "bg-negative-bg-500",
  "negative-600": "bg-negative-bg-600",
  "negative-700": "bg-negative-bg-700",
  "negative-800": "bg-negative-bg-800",
  "negative-900": "bg-negative-bg-900",
  "negative-1000": "bg-negative-bg-1000",
};
