import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriverParams, DriverType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useGetDrivers = ({ limit, skip, search }: DriverParams) => {
  const { isError, error, data, ...query } = useQuery<
    AxiosResponse<DriverType>
  >({
    queryKey: [QUERY_KEYS.DRIVERS, limit, skip, search],
    queryFn: async () =>
      await driversService.getDrivers({ limit, skip, search }),
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
