/**
 * Сервисы для авторизации
 */

import { appConfig } from "@api/config";

const src = appConfig.api.auth;

export const authPaths = {
  refresh_token: `${src}/generate-tokens/`,
  login: `${src}/admin/login/`,
  role: `${src}/me/`,
  send_code: `${src}/send_code/`,
};
