import { fetcher } from "@common/Libs/axios";
import { CitiesType } from "@common/Types";

import { citiesPath } from "./paths";

export const citiesService = {
  getCities: (skip: number, initialLimit: number) =>
    fetcher.get<CitiesType>(citiesPath.cities, {
      params: { skip, initialLimit },
    }),
};
