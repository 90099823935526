import { useId } from "@mantine/hooks";
import clsx from "clsx";
import { Field } from "formik";
import React from "react";

import { isTargetNavi } from "@assets/Flags";

type VariantsType = {
  id: number;
  name: string;
};

type Props = {
  name: string;
  label: string;
  placeholder: string;
  error?: string;
  touched?: boolean;
  variants?: VariantsType[];
};

export const ContactFormSelectField = ({
  name,
  label,
  placeholder,
  error,
  variants = [],
  touched,
}: Props) => {
  const id = useId();
  const htmlFor = `${name}-${id}`;

  return (
    <div className={"w-full flex flex-col"}>
      <div className="flex flex-col items-start">
        <label
          htmlFor={htmlFor}
          className={
            "text-gray-200 text-xs font-medium tracking-wide uppercase mb-1"
          }
        >
          {label}
        </label>
        <div className={"relative w-full"}>
          <Field
            id={htmlFor}
            as="select"
            name={name}
            autoComplete="off"
            className={clsx({
              "placeholder-gray-400 w-full p-4 rounded-xl cursor-pointer focus:outline-none transition-colors bg-dark-400 text-gray-200 border-none":
                isTargetNavi,
              "placeholder-[#969DA1] text-white bg-[#2F3437] text-[16px] w-full py-[10px] px-[16px] rounded-[8px] cursor-pointer focus:outline-none transition-colors appearance-none":
                !isTargetNavi,
            })}
          >
            <option value={""} disabled>
              {placeholder}
            </option>
            {variants.map((city: VariantsType) => (
              <option key={city.name} value={city.id}>
                {city.name}
              </option>
            ))}
          </Field>
        </div>
      </div>
      {error && touched ? (
        <div className="flex justify-start text-sm text-red-600 mt-1">
          {error}
        </div>
      ) : null}
    </div>
  );
};
