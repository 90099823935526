import { fetcher } from "@common/Libs/axios";
import { AccessPages } from "@common/Types";

import { permissionsPath } from "./paths";

/**
 * Описание методов для permissions.
 */

export const permissionsService = {
  getNaviPermissions: () =>
    fetcher.get<AccessPages[]>(permissionsPath.navi_permission),
};
