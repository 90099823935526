import React from "react";

import {
  FacebookLogo,
  InstagramLogo,
  TelegramLogo,
  WhatsappLogo,
} from "@assets/Icons";

export const Contacts: React.FC = () => {
  return (
    <div className="fixed sm:block hidden sm:left-10 left-2 sm:bottom-10 bottom-2 space-y-6 bg-dark-400 p-3 rounded-xl border border-dark-500 z-50">
      <a
        href="https://t.me/navitaxi"
        target={"_blank"}
        rel={"noreferrer"}
        aria-label="Telegram"
        className={
          "block hover:-translate-y-0.5 transform transition-transform"
        }
      >
        <TelegramLogo />
      </a>
      <a
        href="https://api.whatsapp.com/send/?phone=996507722222&text&app_absent=0"
        target={"_blank"}
        rel={"noreferrer"}
        aria-label="Whatsapp"
        className={
          "block hover:-translate-y-0.5 transform transition-transform"
        }
      >
        <WhatsappLogo />
      </a>
      <a
        href="https://www.facebook.com/Navi-Taxi-100218299052930"
        target={"_blank"}
        rel={"noreferrer"}
        aria-label="Facebook"
        className={
          "block hover:-translate-y-0.5 transform transition-transform"
        }
      >
        <FacebookLogo />
      </a>
      <a
        href="https://www.instagram.com/navi.taxi"
        target={"_blank"}
        rel={"noreferrer"}
        aria-label="Instagram"
        className={
          "block hover:-translate-y-0.5 transform transition-transform"
        }
      >
        <InstagramLogo />
      </a>
    </div>
  );
};
