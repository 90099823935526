import { Popover } from "@mantine/core";
import clsx from "clsx";
import React from "react";

import { isTargetNavi } from "@assets/Flags";
import { LanguageType } from "@common/Types";

import { ReactComponent as EnFlagIcon } from "../../assets/icons/en-flag.svg";
import { ReactComponent as KgFlagIcon } from "../../assets/icons/kg-flag.svg";
import { ReactComponent as KzFlagIcon } from "../../assets/icons/kz-flag.svg";
import { ReactComponent as RuFlagIcon } from "../../assets/icons/ru-flag.svg";
import { SvgIconComponentType } from "../../types";

type ItemProps = {
  icon?: SvgIconComponentType;
  label: string;
  onClick: () => void;
  isCurrentLang?: boolean;
  hoverable?: boolean;
};

const getIconByLang = (langKey: string) => {
  switch (langKey) {
    case "ru":
      return RuFlagIcon;
    case "kk":
      return KzFlagIcon;
    case "en":
      return EnFlagIcon;
    case "ky":
      return KgFlagIcon;
    default:
      return undefined;
  }
};

const SelectItem = ({
  icon: Icon,
  label,
  onClick,
  isCurrentLang,
  hoverable,
}: ItemProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        "w-full h-[38px] flex items-center  gap-[12px] py-[4px] px-[10px]",
        {
          "!bg-dark-300": isCurrentLang,
          "hover:bg-dark-300": hoverable,
          "bg-dark-400": isTargetNavi,
          "bg-[#2F3437]": !isTargetNavi,
        },
      )}
    >
      {Icon && (
        <div className={"flex"}>
          <Icon className={"w-[25px] h-[25px] rounded-full"} />
        </div>
      )}
      <div className={"flex"}>
        <span className={"text-white font-medium text-[14px]"}>{label}</span>
      </div>
    </button>
  );
};

type Props = {
  className?: string;
  currentLang: LanguageType;
  availableLangs: LanguageType[];
  onChange: (langKey: string) => void;
};

export const LanguageSwitcherMain = ({
  className,
  availableLangs,
  currentLang,
  onChange,
}: Props) => {
  const [visible, setVisible] = React.useState(false);

  const renderSelectedLang = () => {
    return (
      <div className={"min-w-[96px] flex rounded-[8px] overflow-hidden"}>
        <SelectItem
          icon={getIconByLang(currentLang.value)}
          label={currentLang.label}
          onClick={() => setVisible((m) => !m)}
        />
      </div>
    );
  };

  return (
    <div
      className={clsx(className, {
        "md:-translate-y-1": visible,
      })}
    >
      <Popover
        placement="center"
        position="bottom"
        gutter={10}
        spacing={0}
        opened={visible}
        onClose={() => setVisible(false)}
        transitionDuration={300}
        transition={"pop"}
        withinPortal={false}
        closeOnClickOutside
        target={renderSelectedLang()}
        styles={{
          popover: {
            background: "none",
          },
          body: {
            border: "none",
          },
        }}
      >
        <div
          className={"min-w-[96px] flex flex-col rounded-[8px] overflow-hidden"}
        >
          {availableLangs.map((lang) => {
            return (
              <SelectItem
                key={lang.value}
                icon={getIconByLang(lang.value)}
                label={lang.label}
                isCurrentLang={lang.value === currentLang.value}
                onClick={() => {
                  onChange(lang.value);
                  setVisible(false);
                }}
                hoverable
              />
            );
          })}
        </div>
      </Popover>
    </div>
  );
};
