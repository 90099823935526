import React from "react";

import { AppStore, CreatedByReviro, MainLogo, PlayMarket } from "@assets/Icons";

export const NaviFooter: React.FC = () => {
  return (
    <section className={"bg-dark-500 lg:px-40 md:px-0 py-16 sm:px-0 px-4"}>
      <div className="container mx-auto">
        <div
          className={
            "bg-dark-400 sm:rounded-3xl rounded-xl sm:px-28 sm:py-28 px-6 py-24 relative"
          }
        >
          <div className="flex flex-col items-center justify-center space-y-4">
            <h5 className={"text-white uppercase text-2xl font-bold"}>
              <MainLogo />
            </h5>
            <div className="flex items-center justify-center sm:space-x-8 space-x-0 sm:space-y-0 space-y-4 z-10 sm:flex-nowrap flex-wrap">
              <a
                href="https://play.google.com/store/apps/details?id=reviro.taxi.rider&hl=ru&gl=US"
                target={"_blank"}
                rel="noreferrer"
              >
                <PlayMarket />
              </a>
              <a
                href="https://apps.apple.com/kg/app/navi/id1585148582"
                target={"_blank"}
                rel="noreferrer"
              >
                <AppStore />
              </a>
            </div>
            <a
              href="https://reviro.io/"
              target={"_blank"}
              rel={"noreferrer"}
              aria-label={"Reviro"}
              title={"Reviro"}
            >
              <CreatedByReviro className="absolute bottom-5 right-10" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
