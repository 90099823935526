import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";

export const useDeleteAccount = () => {
  const { isError, error, data, ...query } = useMutation({
    mutationKey: [QUERY_KEYS.DELETE_ACCOUNT],
    mutationFn: async (token: string) =>
      await usersService.deleteAccount(token),
  });

  if (isError) {
    toast.error(error.message);
  }

  const response = data;

  return { isError, error, response, ...query };
};
