import { fetcher } from "@common/Libs/axios";
import { LandingCityType } from "@common/Types";

import { landingCitiesPath } from "./paths";

/**
 * Описание методов landing cities.
 */

export const landingCitiesService = {
  getCities: () => fetcher.get<LandingCityType[]>(landingCitiesPath.cities),
};
