import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { promotionsService } from "@api/services";
import { PromotionSubmitType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useCreatePromotion = () => {
  const { isError, error, data, ...query } = useMutation({
    mutationFn: (data: PromotionSubmitType) =>
      promotionsService.createPromotion(data),
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
