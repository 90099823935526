import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionParams, RiderReferralPromotionType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useGetRidersReferralPromotionParticipants = ({
  promotionId,
  limit,
  skip,
}: PromotionParams) => {
  const { isError, error, data, ...query } = useQuery<
    AxiosResponse<RiderReferralPromotionType>
  >({
    queryKey: [
      QUERY_KEYS.RIDERS_REFERRAL_PROMOTION_PARTICIPANTS,
      promotionId,
      limit,
      skip,
    ],
    queryFn: async () =>
      await promotionsService.getRidersReferralPromotionParticipants({
        promotionId,
        limit,
        skip,
      }),
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  return { isError, error, response, ...query };
};
