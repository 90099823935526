import React, { FC } from "react";

import { ChatMessagesFilterTypes } from "@common/Types";
import { Search } from "@components/Shared";

type Props = {
  filters: ChatMessagesFilterTypes;
  setFilters: (values: ChatMessagesFilterTypes) => void;
};

export const ChatSearch: FC<Props> = ({ filters, setFilters }) => {
  return (
    <div className="w-full h-[40px] border-b border-gray-100">
      <Search
        chat="chat"
        filters={filters}
        setFilters={setFilters}
        placeholder="Поиск"
        field="search"
        width="w-full"
      />
    </div>
  );
};
