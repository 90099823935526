import React from "react";

export const DriversApplicationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.071 4.92893C22.9763 8.83417 22.9763 15.1658 19.071 19.071C15.1658 22.9763 8.83413 22.9763 4.92893 19.071C1.02369 15.1658 1.02369 8.83413 4.92893 4.92893C8.83417 1.02369 15.1658 1.02369 19.071 4.92893"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11V12.675C9 13.129 9.155 13.57 9.438 13.924L9.698 14.249C10.078 14.724 10.652 15 11.26 15H11.785H12.74C13.348 15 13.922 14.724 14.302 14.249L14.562 13.924C14.845 13.57 15 13.129 15 12.675V11C15 9.895 14.105 9 13 9H11C9.895 9 9 9.895 9 11Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 12H12.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 14.8398V18.8298"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 14.8398V18.8298"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.2998 10H9.2798"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7197 10H18.6997"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.08008 13H9.03008"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9697 13H18.9197"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
