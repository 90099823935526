import React, { createContext, useState } from "react";

import { SelectedChatType } from "@common/Types";

type ChatContextType = {
  selectedChat: SelectedChatType | null;
  setSelectedChat: (chat: SelectedChatType | null) => void;
};

export const ChatContext = createContext<ChatContextType>({
  selectedChat: null,
  setSelectedChat: () => void 0,
});

type Props = {
  children: React.ReactNode;
};

export const ChatProvider = ({ children }: Props) => {
  const [selectedChat, setSelectedChat] = useState<SelectedChatType | null>(
    null,
  );

  return (
    <ChatContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
