import { fetcher } from "@common/Libs/axios";
import {
  ChatMessageFilterTypes,
  ChatMessagesFilterTypes,
  ChatMessageTypes
} from "@common/Types";
import { buildCommonFiltersValues, commaSerializer } from "@common/Utils";

import { chatPath } from "./paths";

/**
 * Описание методов support chat.
 */
export const supportChatService = {
  // drivers
  getDriversMessagesById: (id: string, filter?: ChatMessageFilterTypes) => {
    return fetcher.get<ChatMessageTypes>(
      chatPath.support_driver_chat_messages(id),
      {
        params: filter,
        paramsSerializer: commaSerializer,
      },
    );
  },
  getDriversMessages: <T>({
    search,
    city_id,
    skip,
    limit,
  }: ChatMessagesFilterTypes) => {
    return fetcher.get<T>(chatPath.support_driver_chat, {
      params: {
        ...buildCommonFiltersValues({ search, city_id }),
        skip,
        limit,
      },
      paramsSerializer: commaSerializer,
    });
  },

  // riders
  getRidersMessagesById: (id: string, filter?: ChatMessageFilterTypes) => {
    return fetcher.get<ChatMessageTypes>(
      chatPath.support_rider_chat_messages(id),
      {
        params: filter,
        paramsSerializer: commaSerializer,
      },
    );
  },
  getRidersMessages: <T>({
    search,
    city_id,
    skip,
    limit,
  }: ChatMessagesFilterTypes) => {
    return fetcher.get<T>(chatPath.support_rider_chat, {
      params: {
        ...buildCommonFiltersValues({ search, city_id }),
        skip,
        limit,
      },
      paramsSerializer: commaSerializer,
    });
  },
};
