import { fetcher } from "@common/Libs/axios";
import { TariffType } from "@common/Types";

import { tariffsPath } from "./paths";

/**
 * Описание методов tariffs.
 */

export const tariffsService = {
  getTariffs: () => fetcher.get<TariffType[]>(tariffsPath.tariffs),
};
