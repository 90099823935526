import React from "react";

export const RidersIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7926 9.52352C21.5826 10.3136 21.5826 11.5944 20.7926 12.3845C20.0026 13.1745 18.7217 13.1745 17.9316 12.3845C17.1416 11.5944 17.1416 10.3136 17.9316 9.52352C18.7217 8.73349 20.0026 8.73349 20.7926 9.52352"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2026 5.91236C15.4191 7.12884 15.4191 9.10115 14.2026 10.3176C12.9862 11.5341 11.0139 11.5341 9.79737 10.3176C8.58089 9.10116 8.58089 7.12885 9.79737 5.91236C11.0138 4.69588 12.9862 4.69588 14.2026 5.91236"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06846 9.52352C6.85849 10.3136 6.85849 11.5944 6.06846 12.3845C5.27843 13.1745 3.99754 13.1745 3.20751 12.3845C2.41748 11.5944 2.41748 10.3136 3.20751 9.52352C3.99754 8.73349 5.27843 8.73349 6.06846 9.52352"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 19V17.904C23 16.523 21.881 15.404 20.5 15.404H19.699"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19V17.904C1 16.523 2.119 15.404 3.5 15.404H4.301"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3389 19V17.399C17.3389 15.466 15.7719 13.899 13.8389 13.899H10.1599C8.22691 13.899 6.65991 15.466 6.65991 17.399V19"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
