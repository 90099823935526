import { TextAreaView, TextAreaViewStyles } from "./types";

const classes = {
  root: "w-full flex flex-col",
  label: "text-caption-a mb-[4px]",
  description: "text-caption-a mb-[4px]",
  inputDefault:
    "rounded-[4px] border-neutral-border-300 text-neutral-text-800 placeholder-neutral-text-400",
};

export const VIEW_CLASSES: Record<TextAreaView, TextAreaViewStyles> = {
  default: {
    root: classes.root,
    input: classes.inputDefault,
    description: classes.description,
    label: classes.label,
  },
};
