import { NativeFetcher } from "@api/config";
import { apiClient } from "@common/Libs/axios";
import { VerifyCodePrams } from "@common/Types";
import { convertPhoneNumber } from "@common/Utils";

import { usersPath } from "./paths";

/**
 * Описание методов users.
 */

export const usersService = {
  verifyCode: (params: VerifyCodePrams) => {
    return apiClient.post(usersPath.verify_code, {
      code: params.code,
      phone: convertPhoneNumber(params.phone),
    });
  },
  deleteAccount: (accessToken: string) => {
    return NativeFetcher({
      method: "DELETE",
      token: accessToken,
      path: usersPath.delete_account,
    });
  },
};
