import clsx from "clsx";
import React, { useEffect } from "react";

import { isTargetNavi } from "@assets/Flags";
import { Flexbox } from "@components/Elements";
import { Contacts, Footer, Header } from "@components/Shared";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const LandingLayout: React.FC<Props> = ({ children, className }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flexbox
      flexDirection="flex-col"
      className={"min-h-[100vh]"}
      alignItems="items-stretch"
      justifyContent="justify-between"
    >
      <Header />
      <Flexbox
        flexDirection="flex-col"
        alignItems="items-center"
        as="main"
        className={clsx(
          {
            "bg-dark-500": isTargetNavi,
          },
          className,
        )}
      >
        {children}
        {isTargetNavi && <Contacts />}
      </Flexbox>
      <Footer />
    </Flexbox>
  );
};
