import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { QUERY_KEYS } from "@api/config";
import { usersService } from "@api/services";
import { VerifyCodePrams, VerifyCodeTypes } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const usePostVerifyCode = () => {
  const { isError, error, data, ...query } = useMutation({
    mutationKey: [QUERY_KEYS.VERIFY_CODE],
    mutationFn: async (params: VerifyCodePrams) =>
      await usersService.verifyCode(params),
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data as VerifyCodeTypes;

  return { isError, error, response, ...query };
};
