import { Button, Drawer, Group, Input } from "@mantine/core";
import { Check, Funnel } from "phosphor-react";
import React, { useEffect, useState } from "react";

import {
  AvailableFilterKeys,
  DateRageFilterType,
  FilterMapType,
  PartialFiltersType,
  PredefinedDateRangeType,
} from "@common/Types";
import { filtersSchema } from "@components/Shared";

interface Props {
  filters: PartialFiltersType;
  setFilters: (values: PartialFiltersType) => void;
  resetFilters: (values: PartialFiltersType) => void;
  activeFilters: Array<AvailableFilterKeys>;
  filtersMap?: FilterMapType;
  before?: JSX.Element;
  after?: JSX.Element;
}

const getActiveFilterProps = (
  activeFilters: Array<AvailableFilterKeys>,
  filters: PartialFiltersType,
) => {
  return activeFilters.reduce(
    (localFilterState, filterKey: AvailableFilterKeys) => {
      const globalFilter = filters[filterKey];
      if (filterKey === "controlled_date_range") {
        return {
          ...localFilterState,
          date_range:
            filters.date_range ??
            filtersSchema[filterKey].initialValue.date_range,
          select_range:
            filters.select_range ??
            filtersSchema[filterKey].initialValue.select_range,
        };
      }
      localFilterState[filterKey] =
        globalFilter ?? filtersSchema[filterKey].initialValue;
      return localFilterState;
    },
    {} as PartialFiltersType,
  );
};

const getInitialFilterProps = (activeFilters: Array<AvailableFilterKeys>) => {
  return activeFilters.reduce(
    (localFilterState, filterKey: AvailableFilterKeys) => {
      localFilterState[filterKey] = filtersSchema[filterKey].initialValue;
      return localFilterState;
    },
    {} as PartialFiltersType,
  );
};

export const MobileFilters = ({
  filters,
  setFilters,
  resetFilters,
  activeFilters,
  filtersMap,
  before,
  after,
}: Props) => {
  const [localFilters, setLocalFilters] = useState(
    getActiveFilterProps(activeFilters, filters),
  );
  const [showDrawer, setShowDrawer] = useState(false);

  const selectedCity =
    (activeFilters.includes("city_id") && localFilters.city_id) || undefined;

  useEffect(() => {
    if (selectedCity) {
      return () => {
        setLocalFilters((filters) => {
          return {
            ...filters,
            tariff: [],
            parks: [],
          };
        });
      };
    }
  }, [selectedCity]);

  const handleHideDrawer = () => {
    setShowDrawer(false);
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const mapFilterKey = (filterKey: AvailableFilterKeys) => {
    if (filtersMap && filtersMap[filterKey]) {
      return filtersMap[filterKey];
    }
    return filterKey;
  };

  const getFilterValue = (filterKey: AvailableFilterKeys) => {
    if (filterKey === "controlled_date_range") {
      return {
        date_range: localFilters.date_range,
        select_range: localFilters.select_range,
      };
    }

    return localFilters[filterKey];
  };

  const onLocalFilterChange = (filterKey: AvailableFilterKeys) => {
    if (filterKey === "controlled_date_range") {
      return (value: {
        date_range?: DateRageFilterType;
        select_range?: PredefinedDateRangeType;
      }) => {
        setLocalFilters({
          ...localFilters,
          date_range: value?.date_range,
          select_range: value?.select_range,
        });
      };
    }

    return (value: number | string | string[]) => {
      setLocalFilters({
        ...localFilters,
        [filterKey]: Array.isArray(value) ? [...value] : value,
      });
    };
  };

  const onClickToApplyFilters = () => {
    setFilters({ ...localFilters });
    handleHideDrawer();
  };

  const onClickToResetFilters = () => {
    const initialFilterValues = getInitialFilterProps(activeFilters);
    resetFilters(initialFilterValues);
    setLocalFilters(initialFilterValues);
    handleHideDrawer();
  };

  return (
    <div className={"w-full flex flex-col"}>
      <Group spacing={"sm"} position={"apart"}>
        {before && before}
        <Input
          component={"button"}
          icon={<Funnel />}
          onClick={handleShowDrawer}
        >
          Фильтры
        </Input>
        {after && after}
      </Group>
      <Drawer
        zIndex={10}
        opened={showDrawer}
        onClose={onClickToApplyFilters}
        title="Фильтрация"
        padding="lg"
        size="max-content"
        position={"bottom"}
        classNames={{
          drawer: "overflow-auto",
        }}
      >
        <div className={"w-full flex flex-col mb-[20px] gap-[12px]"}>
          {activeFilters.map((filterKey: AvailableFilterKeys) => {
            const FilterToRender = filtersSchema[filterKey].component;
            return (
              <FilterToRender
                key={filterKey}
                filterValue={getFilterValue(
                  mapFilterKey(filterKey) as AvailableFilterKeys,
                )}
                setFilterValue={onLocalFilterChange(
                  mapFilterKey(filterKey) as AvailableFilterKeys,
                )}
                selectedCity={selectedCity}
              />
            );
          })}
        </div>
        <Group spacing={"md"} direction="row" position={"center"}>
          <Button variant="light" color={"red"} onClick={onClickToResetFilters}>
            Сбросить
          </Button>
          <Button
            leftIcon={<Check size={15} color={"#fff"} />}
            onClick={onClickToApplyFilters}
          >
            Применить
          </Button>
        </Group>
      </Drawer>
    </div>
  );
};
