import { fetcher } from "@common/Libs/axios";
import {
  DriverParams,
  DriverType,
  DriverTypes,
  LandingDriverApplicationType,
  ParksFilterType,
} from "@common/Types";
import { buildCommonFiltersValues, commaSerializer } from "@common/Utils";

import { driverPath } from "./paths";

/**
 * Описание методов drivers.
 */

export const driversService = {
  getDriverLocation: (filters?: ParksFilterType) =>
    fetcher.get<DriverTypes>(driverPath.locations, {
      params: buildCommonFiltersValues(filters),
      paramsSerializer: commaSerializer,
    }),
  getDrivers: ({ limit, skip, search }: DriverParams) =>
    fetcher.get<DriverType>(driverPath.drivers, {
      params: { limit, skip, search },
    }),
  sendDriverLandingApplication: (application: LandingDriverApplicationType) =>
    fetcher.post(driverPath.support_applications, application),
};
