import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { authService } from "@api/services";
import { LoginResponse, UserCredentials } from "@common/Types";
import {
  removePlusFromPlaceholder,
  setRefreshTokenLocalStorage,
  setTokenLocalStorage,
  showErrorToast,
} from "@common/Utils";

/**
 * get user
 */

export const useLogin = () => {
  // POST user data with phone and password.
  // RESPONSE getting access_token and refresh_token after status 200
  return useMutation<AxiosResponse<LoginResponse>, Error, UserCredentials>({
    mutationFn: async (payload: UserCredentials) => {
      const { phone, password, placeholder } = payload;
      const authData = new FormData();

      authData.append("phone", removePlusFromPlaceholder(placeholder) + phone);
      authData.append("password", password);

      return await authService.login(authData);
    },
    onSuccess: (response) => {
      setRefreshTokenLocalStorage(response.data.refresh_token);
      setTokenLocalStorage(response.data.access_token);
    },
    onError: (error) => {
      showErrorToast(error as AxiosError);
    },
  });
};
