import React from "react";

export const PlusIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 12H6.5"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 6V18"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2V6"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2V6"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9H21"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4H5C3.895 4 3 4.895 3 6V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V6C21 4.895 20.105 4 19 4Z"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0127 12.729C11.8747 12.729 11.7627 12.841 11.7637 12.979C11.7637 13.117 11.8757 13.229 12.0137 13.229C12.1517 13.229 12.2637 13.117 12.2637 12.979C12.2637 12.841 12.1517 12.729 12.0127 12.729"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0127 12.729C16.8747 12.729 16.7627 12.841 16.7637 12.979C16.7637 13.117 16.8757 13.229 17.0137 13.229C17.1517 13.229 17.2637 13.117 17.2637 12.979C17.2637 12.841 17.1517 12.729 17.0127 12.729"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01268 16.729C6.87468 16.729 6.76268 16.841 6.76368 16.979C6.76368 17.117 6.87568 17.229 7.01368 17.229C7.15168 17.229 7.26368 17.117 7.26368 16.979C7.26368 16.841 7.15168 16.729 7.01268 16.729"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0127 16.729C11.8747 16.729 11.7627 16.841 11.7637 16.979C11.7637 17.117 11.8757 17.229 12.0137 17.229C12.1517 17.229 12.2637 17.117 12.2637 16.979C12.2637 16.841 12.1517 16.729 12.0127 16.729"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArchiveIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8333 17.5H4.16667C3.24583 17.5 2.5 16.7542 2.5 15.8333V4.16667C2.5 3.24583 3.24583 2.5 4.16667 2.5H15.8333C16.7542 2.5 17.5 3.24583 17.5 4.16667V15.8333C17.5 16.7542 16.7542 17.5 15.8333 17.5Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 12.5H6.15167C6.4675 12.5 6.75583 12.6783 6.89667 12.9608L7.26917 13.7058C7.41083 13.9883 7.69917 14.1667 8.015 14.1667H11.985C12.3008 14.1667 12.5892 13.9883 12.73 13.7058L13.1025 12.9608C13.2442 12.6783 13.5325 12.5 13.8483 12.5H17.5"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CashbackIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9902 17L13.9902 19L15.9902 21"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4137 7.58575C14.1947 8.36675 14.1947 9.63275 13.4137 10.4137C12.6327 11.1947 11.3668 11.1947 10.5857 10.4137C9.80475 9.63275 9.80475 8.36675 10.5857 7.58575C11.3668 6.80475 12.6327 6.80475 13.4137 7.58575"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 7.5V10.5"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 7.5V10.5"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15H5C3.895 15 3 14.105 3 13V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V11"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 15H19C20.105 15 21 15.895 21 17V17C21 18.105 20.105 19 19 19H14"
        stroke="#64748B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
