/**
 * Сервисы для drivers
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;
const driversSrc = appConfig.api.drivers;

export const driverPath = {
  locations: `${src}/drivers/locations/`,
  support_applications: `${driversSrc}/support-applications/`,
  drivers: `${driversSrc}/drivers/`,
};
