import { format } from "date-fns";
import { ru } from "date-fns/locale";

const options = {
  locale: ru,
};

export const formatDate = (
  date?: Date | string,
  formatType = "dd-MM-yyyy",
): string | null => {
  if (!date) return null;
  return format(new Date(date), formatType.toString(), options);
};
