import React from "react";

import { useDimension } from "@common/Hooks";
import {
  AvailableFilterKeys,
  LocalFiltersType,
  PartialFiltersType,
} from "@common/Types";
import { MobileFilters } from "@components/Shared";

import { DesktopFilters } from "../desktop-filters";

type AdditionalFiltersType = "city_id" | "inline";

type Props = {
  filters: PartialFiltersType;
  setFilters: (values: PartialFiltersType) => void;
  field: AvailableFilterKeys;
  activeMobileFilters: AvailableFilterKeys[];
  activeDesktopFilters: AdditionalFiltersType[];
  filterData: LocalFiltersType[];
};

export const GeneralFilters = ({
  filters,
  setFilters,
  field,
  activeMobileFilters,
  activeDesktopFilters,
  filterData,
}: Props) => {
  const { isDesktop } = useDimension();

  const handleFilterReset = (filterValue: PartialFiltersType) => {
    setFilters(filterValue);
  };

  const handleUpdateFilter = (filterValue: PartialFiltersType) => {
    setFilters(filterValue);
  };

  return (
    <div className="w-full flex items-center flex-wrap">
      {isDesktop ? (
        <DesktopFilters
          filters={filters}
          setFilters={handleUpdateFilter}
          field={field}
          filterData={filterData}
          activeFilters={activeDesktopFilters}
        />
      ) : (
        <MobileFilters
          filters={filters}
          setFilters={handleUpdateFilter}
          resetFilters={handleFilterReset}
          activeFilters={activeMobileFilters}
        />
      )}
    </div>
  );
};
