import { useId } from "@mantine/hooks";
import clsx from "clsx";
import { Field } from "formik";
import React from "react";

import { isTargetNavi } from "@assets/Flags";

type Props = {
  type?: "text" | "email" | "number";
  name: string;
  label: string;
  placeholder: string;
  error?: string;
  touched?: boolean;
};

export const ContactFormInputField = ({
  name,
  type = "text",
  label,
  placeholder,
  error,
  touched,
}: Props) => {
  const id = useId();
  const htmlFor = `${name}-${id}`;

  return (
    <div className={"w-full flex flex-col"}>
      <div className="flex flex-col items-start">
        <label
          htmlFor={htmlFor}
          className={
            "text-gray-200 text-xs font-medium tracking-wide uppercase mb-1"
          }
        >
          {label}
        </label>
        <Field
          id={htmlFor}
          name={name}
          type={type}
          placeholder={placeholder}
          className={clsx({
            "placeholder-gray-400 w-full p-4 border-none rounded-xl focus:outline-none transition-colors bg-dark-400 text-gray-200":
              isTargetNavi,
            "placeholder-[#969DA1] text-white bg-[#2F3437] text-[16px] w-full py-[10px] px-[16px] rounded-[8px] focus:outline-none transition-colors":
              !isTargetNavi,
          })}
          autoComplete="off"
        />
      </div>
      {error && touched ? (
        <div className="flex justify-start text-sm text-red-600 mt-1">
          {error}
        </div>
      ) : null}
    </div>
  );
};
