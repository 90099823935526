import React from "react";
import { useTranslation } from "react-i18next";

import { AVAILABLE_LANGUAGE_FILTER } from "@common/Libs/i18next";

import {
  LanguageSwitcherMain,
  MobileMenuLanguageSwitcher,
  PrivacyLangSwitcher,
} from "./components/shared";

const AVAILABLE_LANGUAGES = AVAILABLE_LANGUAGE_FILTER;

const getCurrentLang = (langKey: string) => {
  const currentLang = AVAILABLE_LANGUAGES.find(
    (lang) => lang.value === langKey,
  );

  if (currentLang) return currentLang;
  return AVAILABLE_LANGUAGES[1];
};

type Props = {
  className?: string;
  isMobile?: boolean;
  isPolitics?: boolean;
};

export const LanguageSwitcher = (props: Props) => {
  const { i18n } = useTranslation();

  const handleChangeLang = (langKey: string) => {
    i18n.changeLanguage(langKey);
  };

  const currentLang = getCurrentLang(i18n.language);

  if (props.isMobile) {
    return (
      <MobileMenuLanguageSwitcher
        {...props}
        availableLangs={AVAILABLE_LANGUAGES}
        onChange={handleChangeLang}
      />
    );
  }

  if (props.isPolitics) {
    return <PrivacyLangSwitcher />;
  }

  return (
    <LanguageSwitcherMain
      {...props}
      currentLang={currentLang}
      availableLangs={AVAILABLE_LANGUAGES}
      onChange={handleChangeLang}
    />
  );
};
