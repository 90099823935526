import { Space, Text } from "@mantine/core";
import React from "react";

import { EmptyPageIcon } from "@assets/Icons";

export const NoResult = ({
  text,
  className,
}: {
  className?: string;
  text?: string;
}) => {
  return (
    <div
      className={`flex flex-col bg-[#FFFFFF] w-full items-center justify-center z-10 pt-[40px] pb-[120px] rounded-[12px] shadow-md ${className}`}
    >
      <EmptyPageIcon />
      <Space h={"xs"} />
      <Text
        className={
          "text-center text-neutral-text-800 text-body-1-b font-semibold"
        }
      >
        {text ? text : "По вашему запросу ничего не найдено"}
      </Text>
    </div>
  );
};
