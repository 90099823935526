import { ROUTES } from "@common/Constants";

// Функция для определения типа отправителя
const getQuerySender = (sender: string): string => {
  switch (sender) {
    case "driver":
      return "drivers";
    case "rider":
      return "riders";
    default:
      return "";
  }
};

export const getLinkToChatByMessageSender = (sender: string, id = "") => {
  const querySender = getQuerySender(sender);

  let url = `${ROUTES.CHAT}`;

  if (querySender) url += `?${querySender}`;
  if (id) url += `=${id}`;

  return url;
};

export const parseChatSender = (sender: string): string => {
  switch (sender) {
    case "driver":
      return "Новое сообщение от водителя";
    case "rider":
      return "Новое сообщение от пассажира";
    default:
      return sender;
  }
};
