import React from "react";

export const PencilIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H24C28.1421 0.5 31.5 3.85786 31.5 8V24C31.5 28.1421 28.1421 31.5 24 31.5H8C3.85786 31.5 0.5 28.1421 0.5 24V8Z"
        stroke="#BBBABE"
      />
      <path
        d="M20.6164 14.4333L17.5664 11.3833"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2092 23.4999H8.5V20.7908C8.5 20.5699 8.5875 20.3574 8.74417 20.2016L19.8558 9.0891C20.1817 8.76327 20.7092 8.76327 21.0342 9.0891L22.91 10.9649C23.2358 11.2908 23.2358 11.8183 22.91 12.1433L11.7983 23.2558C11.6425 23.4124 11.43 23.4999 11.2092 23.4999V23.4999Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
