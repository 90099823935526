/**
 * Сервисы для cities
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const citiesPath = {
  cities: `${src}/cities/`,
};
