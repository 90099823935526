import React from "react";

import { QuestionType } from "@common/Types";

type Props = {
  data: QuestionType[];
  onClick: (path: string) => void;
};

export const Question = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className={
        "relative w-full h-[64px] flex items-center pr-[38px] border-b-[1px] border-solid border-[#D9D9D9]"
      }
    >
      <div className={"flex"}>
        <span className={"text-[16px] leading-[1.2] font-normal"}>{text}</span>
      </div>
      <div className={"absolute right-0"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_11041_21499)">
            <path
              d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z"
              fill="#26282C"
            />
          </g>
          <defs>
            <clipPath id="clip0_11041_21499">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

const FAQQuestions = ({ data, onClick }: Props) => {
  return (
    <div className={"w-full flex flex-col pl-[16px] pr-[8px]"}>
      {data.map((item) => {
        return (
          <Question
            key={item.path}
            text={item.question}
            onClick={() => onClick(item.path)}
          />
        );
      })}
    </div>
  );
};

export default FAQQuestions;
