import { ROLES_MOTOR, ROLES_NAVI } from "./ENUMS";
import { ROUTES } from "./ROUTES";

export const PERMISSIONS_NAVI = {
  [ROLES_NAVI.SUPERADMIN]: [
    ROUTES.DASHBOARD,
    ROUTES.APPLICATIONS,
    ROUTES.APPLICATIONS_DETAIL,
    ROUTES.PARKS_APPLICATIONS,
    ROUTES.PARKS,
    ROUTES.PARKS_DETAIL,
    ROUTES.PHOTO_CONTROL,
    ROUTES.PHOTO_CONTROL_DETAIL,
    ROUTES.CITIES,
    ROUTES.CITIES_CREATE,
    ROUTES.CITIES_DETAIL,
    ROUTES.FRAUD_CONTROL,
    ROUTES.CITIES_DETAIL_EDIT,
    ROUTES.RIDES,
    ROUTES.RIDES_DETAIL,
    ROUTES.DRIVERS,
    ROUTES.DRIVERS_DETAIL,
    ROUTES.DRIVERS_DETAIL_EDIT,
    ROUTES.RIDERS,
    ROUTES.RIDERS_DETAIL,
    ROUTES.CORPORATE_CLIENTS,
    ROUTES.CORPORATE_CLIENTS_DETAIL,
    ROUTES.TRANSACTIONS,
    ROUTES.NEWS,
    ROUTES.NEWS_CREATE, // TODO: remove on feature
    ROUTES.STATISTICS,
    ROUTES.REPORT,
    ROUTES.SETTINGS,
    ROUTES.CHAT,
    ROUTES.USERS,
    ROUTES.PROMOTIONS,
    ROUTES.PROMOTIONS_DETAIL,
  ],
  [ROLES_NAVI.CITYSUPERADMIN]: [
    ROUTES.DASHBOARD,
    ROUTES.APPLICATIONS,
    ROUTES.APPLICATIONS_DETAIL,
    ROUTES.PARKS_APPLICATIONS,
    ROUTES.PARKS,
    ROUTES.PARKS_DETAIL,
    ROUTES.PHOTO_CONTROL,
    ROUTES.PHOTO_CONTROL_DETAIL,
    ROUTES.CITIES,
    ROUTES.CITIES_DETAIL,
    ROUTES.FRAUD_CONTROL,
    ROUTES.RIDES,
    ROUTES.RIDES_DETAIL,
    ROUTES.DRIVERS,
    ROUTES.DRIVERS_DETAIL,
    ROUTES.DRIVERS_DETAIL_EDIT,
    ROUTES.RIDERS,
    ROUTES.RIDERS_DETAIL,
    ROUTES.TRANSACTIONS,
    ROUTES.NEWS,
    ROUTES.NEWS_CREATE,
    ROUTES.STATISTICS,
    ROUTES.REPORT,
    ROUTES.CHAT,
    ROUTES.USERS,
  ],
  [ROLES_NAVI.NAVIADMIN]: [], // get from api
  [ROLES_NAVI.PARKADMIN]: [
    ROUTES.DRIVERS_DETAIL,
    ROUTES.DRIVERS,
    ROUTES.MY_PARK_DETAIL,
    ROUTES.RIDES_DETAIL,
    ROUTES.RIDES,
  ],
  [ROLES_NAVI.RIDER]: [],
  [ROLES_NAVI.DRIVER]: [],
};

export const PERMISSIONS_MOTOR = {
  [ROLES_MOTOR.SUPERADMIN]: [
    ROUTES.DASHBOARD,
    ROUTES.APPLICATIONS,
    ROUTES.APPLICATIONS_DETAIL,
    ROUTES.PARKS_APPLICATIONS,
    ROUTES.PHOTO_CONTROL,
    ROUTES.PHOTO_CONTROL_DETAIL,
    ROUTES.CITIES,
    ROUTES.CITIES_CREATE,
    ROUTES.CITIES_DETAIL,
    ROUTES.CITIES_DETAIL_EDIT,
    ROUTES.FRAUD_CONTROL,
    ROUTES.RIDES,
    ROUTES.RIDES_DETAIL,
    ROUTES.DRIVERS,
    ROUTES.DRIVERS_DETAIL,
    ROUTES.DRIVERS_DETAIL_EDIT,
    ROUTES.RIDERS,
    ROUTES.RIDERS_DETAIL,
    ROUTES.CORPORATE_CLIENTS,
    ROUTES.CORPORATE_CLIENTS_DETAIL,
    ROUTES.PARKS,
    ROUTES.PARKS_DETAIL,
    ROUTES.TRANSACTIONS,
    ROUTES.NEWS,
    ROUTES.NEWS_CREATE,
    ROUTES.PROMOTIONS,
    ROUTES.PROMOTIONS_DETAIL,
    ROUTES.STATISTICS,
    ROUTES.REPORT,
    ROUTES.CHAT,
    ROUTES.SETTINGS,
    ROUTES.USERS,
  ],
  [ROLES_MOTOR.CITYSUPERADMIN]: [
    ROUTES.DASHBOARD,
    ROUTES.APPLICATIONS,
    ROUTES.APPLICATIONS_DETAIL,
    ROUTES.PARKS_APPLICATIONS,
    ROUTES.PHOTO_CONTROL,
    ROUTES.PHOTO_CONTROL_DETAIL,
    ROUTES.CITIES,
    ROUTES.CITIES_DETAIL,
    ROUTES.FRAUD_CONTROL,
    ROUTES.RIDES,
    ROUTES.RIDES_DETAIL,
    ROUTES.DRIVERS,
    ROUTES.DRIVERS_DETAIL,
    ROUTES.DRIVERS_DETAIL_EDIT,
    ROUTES.RIDERS,
    ROUTES.RIDERS_DETAIL,
    ROUTES.PARKS,
    ROUTES.PARKS_DETAIL,
    ROUTES.TRANSACTIONS,
    ROUTES.NEWS,
    ROUTES.NEWS_CREATE,
    ROUTES.STATISTICS,
    ROUTES.REPORT,
    ROUTES.CHAT,
    ROUTES.USERS,
  ],
  [ROLES_MOTOR.MOTORADMIN]: [], // get from api
  [ROLES_MOTOR.PARKADMIN]: [
    ROUTES.DRIVERS_DETAIL,
    ROUTES.DRIVERS,
    ROUTES.MY_PARK_DETAIL,
    ROUTES.RIDES_DETAIL,
    ROUTES.RIDES,
  ],
  [ROLES_MOTOR.RIDER]: [],
  [ROLES_MOTOR.DRIVER]: [],
};
