import { apiClient } from "@common/Libs/axios";
import { LandingNews } from "@common/Types";

import { landingNewsPath } from "./paths";

/**
 * Описание методов news.
 */

export const landingNewsService = {
  getNews: (skip: number, limit: number) =>
    apiClient.get<LandingNews[]>(landingNewsPath.news, {
      params: { skip, limit },
    }),
};
