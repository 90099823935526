import { appConfig } from "@api/config";

/**
 * Сервисы для permission
 */

const src = appConfig.api.dashboard;

export const permissionsPath = {
  navi_permission: `${src}/admin_navi/permission/`,
};
