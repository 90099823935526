import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import {
  FraudControlReasonType,
  ReasonAniFraudsQueryParams,
} from "@common/Types";
import { showErrorToast } from "@common/Utils";

type Params = ReasonAniFraudsQueryParams & {
  enabled?: boolean;
};

export const useGetReasonAntiFrauds = ({
  enabled = true,
  ...params
}: Params) => {
  const { isError, error, data, ...query } = useQuery<
    AxiosResponse<FraudControlReasonType[]>
  >({
    queryKey: [QUERY_KEYS.ANTI_FRAUDS_REASON, params],
    queryFn: async () => await antiFraudsService.getReasonAntiFraud(params),
    enabled,
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  const response = data?.data;

  const sortedData = response?.sort(
    (a: FraudControlReasonType, b: FraudControlReasonType) => a.id - b.id,
  );

  return { isError, error, response: sortedData, ...query };
};
