import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { paymentsService } from "@api/services";
import { PaymentType } from "@common/Types";

export const useSavePaymentCard = () => {
  const { isError, error, data, isSuccess, ...query } = useMutation({
    mutationKey: [QUERY_KEYS.PAYMENTS_SAVE],
    mutationFn: async (accessToken: string) => {
      return (await paymentsService.saveCardRequest(
        accessToken,
      )) as PaymentType;
    },
  });

  if (isError) {
    toast.error(error?.message);
  }

  const response = data;

  return { isError, error, response, isSuccess, ...query };
};
