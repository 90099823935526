import { LocalFiltersType, TariffType } from "@common/Types";

export const buildTariffsOptions = (
  data?: TariffType[],
): LocalFiltersType[] | undefined => {
  return data?.map((tariff) => ({
    label: tariff.name,
    value: tariff.id.toString(),
  }));
};
