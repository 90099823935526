import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { LandingParkApplicationType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useCreateLandingParkApplications = () => {
  let loading_toast;

  const { isError, error, data, isSuccess, ...query } = useMutation({
    mutationKey: [QUERY_KEYS.LANDING_PARK_APPLICATION],
    mutationFn: async (application: LandingParkApplicationType) => {
      loading_toast = toast.loading("Отправляем вашу заявку...");

      return await parksService.sendParkApplication(application);
    },
  });

  if (isError) {
    showErrorToast(error as AxiosError);
    toast.remove(loading_toast);
  }

  if (isSuccess) {
    toast.success("Ваша заявка успешно доставлена!", {
      id: loading_toast,
    });
  }

  const response = data?.data;

  return { isError, error, response, isSuccess, ...query };
};
