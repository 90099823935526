import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";

import { FRAUD_STATUSES } from "@common/Constants";
import { VerdictFraudControlType } from "@common/Types";
import { verdictValidationSchema } from "@common/Validation";
import { Button, Modal, TextArea } from "@components/Elements";

type Props = {
  open: boolean;
  type: FRAUD_STATUSES | null;
  onClose: () => void;
  onSubmit: (description: string) => Promise<void>;
};

const initialFormValues = {
  description: "",
};

export const FraudControlVerdictModal = ({
  open,
  type,
  onClose,
  onSubmit,
}: Props) => {
  const [title, setTitle] = useState("");
  const [btnLabel, setBtnLabel] = useState("");

  useEffect(() => {
    if (!open) return;

    if (type === FRAUD_STATUSES.UNLOCKED) {
      setTitle("Одобрить заявку");
      setBtnLabel("Одобрить");
    }
    if (type === FRAUD_STATUSES.BLOCKED) {
      setTitle("Отклонить заявку");
      setBtnLabel("Отклонить");
    }
  }, [open]);

  const confirmForm = async (
    values: VerdictFraudControlType,
    { resetForm }: FormikHelpers<VerdictFraudControlType>,
  ) => {
    await onSubmit(values.description);
    onClose();
    resetForm();
  };

  return (
    <Modal title={title} opened={open} onClose={onClose}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={verdictValidationSchema}
        onSubmit={confirmForm}
      >
        {({ errors, touched, values, isSubmitting }) => {
          return (
            <Form className="mt-6 space-y-6">
              <Field
                name="description"
                label="Причина"
                placeholder="Напишите причину"
                required={true}
                value={values.description}
                error={(touched.description && errors.description) || null}
                minRows={2}
                as={TextArea}
              />

              <Button
                size="lg"
                type="submit"
                loading={isSubmitting}
                className="text-body-1-b w-full justify-center"
              >
                {btnLabel}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
