import { Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePostSendCode } from "@api/queries";
import { LoadingIcon } from "@assets/Icons";
import { ContactFormPhoneField } from "@components/Elements";

import { StateValues } from "../../common/types";
import { RequestAuthCodeFormValidationSchema } from "../../common/utils";

export type RequestAuthCodeForm = {
  phone: string;
};

const initialValues: RequestAuthCodeForm = {
  phone: "",
};

type Props = {
  values: StateValues;
  onChange: (values: StateValues) => void;
};

export const RequestAuthCodeForm = ({ onChange }: Props) => {
  const { t, i18n } = useTranslation();

  const { mutateAsync: requestAuthCode, isPending: loading } =
    usePostSendCode();

  const validationSchema = useMemo(() => {
    return RequestAuthCodeFormValidationSchema(t);
  }, [i18n.language]);

  const onSubmit = async (
    values: RequestAuthCodeForm,
    { resetForm }: FormikHelpers<RequestAuthCodeForm>,
  ) => {
    await requestAuthCode(values.phone).then(() => {
      onChange(values);
      resetForm();
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form className={"w-full space-y-6"}>
          <ContactFormPhoneField
            name={"phone"}
            label={t("delete-account.phone-field")}
            placeholder={t("delete-account.phone-field-placeholder")}
            error={errors.phone}
            touched={touched.phone}
          />
          <div className={"flex w-full items-center justify-center"}>
            <button
              type={"submit"}
              disabled={loading}
              className="inline-flex items-center justify-center h-10 px-6 space-x-2 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-600 hover:bg-blue-700 focus:shadow-outline focus:outline-none"
            >
              {loading && <LoadingIcon />}
              <span>{t("delete-account.request-code-button")}</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
