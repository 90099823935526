import { fetcher } from "@common/Libs/axios";
import {
  DriverReferralPromotionType,
  PromotionDetailType,
  PromotionFiltersType,
  PromotionParams,
  PromotionParticipantType,
  PromotionReportType,
  PromotionSubmitType,
  PromotionType,
  RideIndividualPromotionParticipantType,
  RidePromotionReportType,
  RiderReferralPromotionType,
  TaxiParkPromotionParticipantType,
} from "@common/Types";

import { promotionsPath } from "./paths";

/**
 * Описание методов promotion.
 */

export const promotionsService = {
  getPromotions: (filters?: PromotionFiltersType) =>
    fetcher.get<PromotionType>(promotionsPath.promotions, {
      params: filters,
    }),
  createPromotion: (data: PromotionSubmitType) =>
    fetcher.post<PromotionSubmitType>(promotionsPath.promotions, data),
  getPromotionDetails: (promotionId: string) =>
    fetcher.get<PromotionDetailType>(
      promotionsPath.promotion_details(promotionId),
    ),
  archivePromotion: (promotionId: string) =>
    fetcher.patch(promotionsPath.archive_promotion(promotionId)),
  getPromotionReport: (promotionId: string) =>
    fetcher.get<PromotionReportType>(
      promotionsPath.promotion_report(promotionId),
    ),
  getReferralPromotionReports: (promotionId: string) =>
    fetcher.get<PromotionReportType>(
      promotionsPath.referral_promotion_reports(promotionId),
    ),
  getPromotionParticipants: ({ promotionId, limit, skip }: PromotionParams) =>
    fetcher.get<PromotionParticipantType[]>(
      promotionsPath.promotion_participants(promotionId),
      {
        params: { limit, skip },
      },
    ),
  getDriversReferralPromotionParticipants: ({
    promotionId,
    limit,
    skip,
  }: PromotionParams) =>
    fetcher.get<DriverReferralPromotionType>(
      promotionsPath.drivers_referral_promotion_participants(promotionId),
      {
        params: { limit, skip },
      },
    ),
  getRidersReferralPromotionParticipants: ({
    promotionId,
    limit,
    skip,
  }: PromotionParams) =>
    fetcher.get<RiderReferralPromotionType>(
      promotionsPath.riders_referral_promotion_participants(promotionId),
      { params: { limit, skip } },
    ),
  getRidePromotionReports: (promotionId: string) =>
    fetcher.get<RidePromotionReportType>(
      promotionsPath.ride_promotion_reports(promotionId),
    ),
  getRideIndividualPromotionParticipants: (promotionId: string) =>
    fetcher.get<RideIndividualPromotionParticipantType[]>(
      promotionsPath.ride_individual_promotion_participants(promotionId),
    ),
  getPromotionParksParticipants: (promotionId: string) =>
    fetcher.get<TaxiParkPromotionParticipantType[]>(
      promotionsPath.promotion_parks_participants(promotionId),
    ),
  deletePromotion: (promotionId: string) =>
    fetcher.delete<PromotionDetailType>(
      promotionsPath.delete_promotion(promotionId),
    ),
};
