import { NotificationsProvider } from "@mantine/notifications";
import React from "react";

import { ChatProvider, SocketProvider } from "@common/Providers";

type Props = {
  children: React.ReactNode;
};

export const DashboardProvidersLayout: React.FC<Props> = ({ children }) => {
  return (
    <ChatProvider>
      <SocketProvider>
        <NotificationsProvider autoClose={10000} limit={5}>
          {children}
        </NotificationsProvider>
      </SocketProvider>
    </ChatProvider>
  );
};
