import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { InitialSettingsType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

type Params = InitialSettingsType;

export const useUpdateSettingsAntiFrauds = () => {
  const queryClient = useQueryClient();

  const { isError, error, ...query } = useMutation({
    mutationFn: async (params: Params) =>
      await antiFraudsService.updateSettingsAntiFraud(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ANTI_FRAUDS_SETTINGS],
      });

      toast.success("Настройки успешно обновлены");
    },
  });

  if (isError) {
    showErrorToast(error as AxiosError);
  }

  return { isError, error, ...query };
};
