import React from "react";

import {
  AvailableFilterKeys,
  LocalFiltersType,
  PartialFiltersType,
} from "@common/Types";

import { filtersSchema } from "./filters-schema";

type AdditionalFiltersType = "city_id" | "inline";

type Props = {
  filters: PartialFiltersType;
  setFilters: (values: PartialFiltersType) => void;
  field: AvailableFilterKeys;
  activeFilters: AdditionalFiltersType[];
  filterData: LocalFiltersType[];
};

export const DesktopFilters = ({
  filters,
  setFilters,
  field,
  activeFilters,
  filterData,
}: Props) => {
  return (
    <div className="w-full flex items-center justify-between flex-wrap">
      <div className="flex items-center flex-wrap gap-2">
        {activeFilters.map((item, index) => {
          const FileToRender = filtersSchema[item]?.component;

          return (
            <FileToRender
              filters={filters}
              setFilters={setFilters}
              key={index}
              filterData={filterData}
              field={field}
            />
          );
        })}
      </div>
    </div>
  );
};
