import qs from "query-string";
import { chain, isEmpty } from "radash";

export const commaSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "comma" });
};

export const defaultSerializer = <T extends Record<string, any>>(
  filters: T,
) => {
  return qs.stringify(filters);
};

export const noneSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "none" });
};

//  API helpers
export const removeEmptyFilterValues = (
  filters: Record<string, any> | null | undefined,
): Record<string, any> | null | undefined => {
  if (!filters) return filters;

  return Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => !isEmpty(value)),
  );
};

export const buildCommonFiltersValues = <T>(filters: T): Partial<T> => {
  const chained = chain(removeEmptyFilterValues);
  return chained(filters);
};
